import {Box, Container} from "@mui/material";

export const PageContent = ({children}) => {

  return (
    <>
      <Box component="main" sx={{flex: 1, py: {xs: 3, md: 3}, px: {xs: 0, md: 4}, bgcolor: '#eaeff1'}}>
        <Container sx={{maxWidth: {xs: "95vw", sm: "800px", lg: "1200px"}, overflow: 'hidden'}}>
          {children}
        </Container>
      </Box>
    </>
  )
}