import {PageHeader} from "../../../components/PageHeader";
import * as React from "react";
import {CircularProgress, Grid, Paper} from "@mui/material";
import {UserTopPanel} from "./UserTopPanel";
import {AUCTION_USERS} from "../../../_mocks/auctionUsers";
import {useParams} from "react-router-dom";
import {UserBidsPanel} from "./UserBidsPanel";
import {UserLimitsPanel} from "./UserLimitsPanel";
import {PageContent} from "../../../components/PageContent";
import {useTranslation} from "react-i18next";
import useUser from "../../../hooks/useUser";

export const AuctionsUserDetail = () => {
  const {auctionId, userId} = useParams();
  const {t} = useTranslation();
  const [user, userLoading] = useUser(userId, auctionId);

  return (
    <>
      {userLoading
       ? (<CircularProgress/>)
        : (<>
            <Paper elevation={1} sx={{px: 4, py: 2, mb: 2}}>
              <UserTopPanel user={user} auctionId={auctionId} userId={userId}></UserTopPanel>
            </Paper>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Paper elevation={1} sx={{px: 4, py: 2, mb: 2}}>
                  <UserBidsPanel user={user} auctionId={auctionId} userId={userId}/>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper elevation={1} sx={{px: 4, py: 2, mb: 2}}>
                  <UserLimitsPanel auctionId={auctionId} userId={userId}/>
                </Paper>
              </Grid>
            </Grid>
          </>)
      }
    </>
  );
}