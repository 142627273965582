import {DataTable, DataTableActionButton, DataTableActionMenu} from "../../../components/DataTable";
import {
  ADMIN_AUCTIONS_EDIT,
  ADMIN_AUCTIONS_ITEMS_DETAIL,
  ADMIN_AUCTIONS_USERS_ADD,
  ADMIN_AUCTIONS_USERS_DETAIL, ADMIN_AUCTIONS_USERS_EDIT,
  ADMIN_USERS_ADD,
  AUCTION_ID, ITEM_ID,
  USER_ID
} from "../../../navigation/CONSTANTS";
import * as React from "react";
import Box from "@mui/material/Box";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import AlertDialogYesNo from "../../../components/AlertDialogYesNo";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {usePreferredPagination} from "../../../hooks/usePagination";
import {Divider} from "@mui/material";
import {BuyerSearchFilter} from "./BuyerSearchFilter";
import {withDefinedFields} from "../../../utils/idUtils";

const columns = (t, handleOpenMenu) => ([
  {
    field: 'regNumber',
    headerName: t('pages.admin.users.index.tableCol.regNumber'),
    disableColumnMenu: true,
    sortable: false,
    width: 100
  },
  {
    field: 'name',
    headerName: t('pages.admin.users.index.tableCol.name'),
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'email',
    headerName: t('pages.admin.users.index.tableCol.email'),
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'actions',
    headerName: '',
    width: 30,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: 'center',
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" sx={{width: '100%'}}>
          <DataTableActionButton size="small" onClick={(e) => handleOpenMenu(e, params.row)}>
            <MoreVertIcon fontSize="small" />
          </DataTableActionButton>
        </Box>
      )
    },
  },
]);

export const Buyers = () => {
  const {t} = useTranslation();
  const {auctionId} = useParams();
  const [buyers, setBuyers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let [paginationModel, setPaginationModel] = usePreferredPagination('pag_a_buyers');
  let [rowCount, setRowCount] = useState(0);
  const [confirmRemoveUserId, setConfirmRemoveUserId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({});
  const axios = useAxiosAuth();
  const navigate = useNavigate();


  useEffect(() => {
    setIsLoading(true);
  }, [paginationModel]);

  useEffect(() => {
    let isMounted = true;
    if (!isLoading) {
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/auctions/${auctionId}/users`, {
          params: {
            ...searchCriteria,
            page: paginationModel.page,
            size: paginationModel.pageSize
          }
        })
        isMounted && setBuyers(response.data?.data);
        isMounted && setRowCount(response.data?.page.totalCount);
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [isLoading]);

  const showDeleteAlert = (row) => {
    setConfirmRemoveUserId(row.id);
  }

  const closeDeleteAlert = () => {
    setConfirmRemoveUserId(null);
  }

  const removeUserFromAuction = async () => {
    try {
      await axios.delete(`/api/auctions/${auctionId}/users/${confirmRemoveUserId}`, {params: {removeAccount: true}});
      closeDeleteAlert();
      setIsLoading(true);
    } catch (err) {
      console.error(err);
    }
  }

  const handleOpenMenu = (e, row) => {
    setAnchorEl({element: e.target, row: row});
    e.stopPropagation();
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleEditActionMenu = (row) => {
    const editPath = ADMIN_AUCTIONS_USERS_EDIT
      .replace(AUCTION_ID, auctionId)
      .replace(USER_ID, row.id);

    navigate(editPath);
  }

  const onSearchCriteriaChange = (change) => {
    setSearchCriteria(withDefinedFields({...searchCriteria, ...change}));
    setIsLoading(true);
  }

  let gridOptions = {
    paginationMode: 'server',
    pageSizeOptions: [10, 25, 50, 100],
    initialState: {
      pagination: { paginationModel: paginationModel },
    },
    onPaginationModelChange: setPaginationModel,
    loading: isLoading,
    rowCount,
    onRowClick: function (params) {
      navigate(ADMIN_AUCTIONS_USERS_DETAIL.replace(AUCTION_ID, auctionId).replace(USER_ID, params.row.id));
    }
  }

  return (
    <>
      <DataTable
        rows={buyers}
        columns={columns(t, handleOpenMenu)}
        addNewLink={ADMIN_AUCTIONS_USERS_ADD.replace(AUCTION_ID, auctionId)}
        pagination={paginationModel}
        gridOptions={gridOptions}>

        <Divider sx={{mb: 1}}/>
        <BuyerSearchFilter onSearchCriteriaChange={onSearchCriteriaChange}/>

      </DataTable>

      {confirmRemoveUserId &&
        <AlertDialogYesNo
          title={t('general.modal.delete')}
          content={t('pages.admin.users.index.removeUserFromAuction')}
          onYes={removeUserFromAuction}
          onNo={closeDeleteAlert}
          onClose={closeDeleteAlert}/>
      }

      {anchorEl &&
        <DataTableActionMenu
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          onEdit={handleEditActionMenu}
          onDelete={showDeleteAlert}
        />
      }
    </>
  );
}