import {BASE_STATIC_URL} from "../api/staticFiles";
import {Box} from "@mui/material";
import {BASE_NAME} from "../App";

export const StaticImage = ({photo, variant, sx}) => {
  const photos = photo && photo.length ? photo : [photo || {}];
  const imageSrc = photos?.find(photo => photo.variant === variant)?.url;
  const fullImageUrl = imageSrc
    ? BASE_STATIC_URL.endsWith("/")
      ? BASE_STATIC_URL + imageSrc
      : BASE_STATIC_URL + '/' + imageSrc
    : `${BASE_NAME}/static/logo250.png`;

  return (
    <Box
      component="img"
      src={fullImageUrl}
      sx={{
        ...sx,
        objectFit: 'contain',
      }}
    />
  );
}