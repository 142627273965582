import {createContext, useEffect, useRef} from "react";
import {ADMIN_LOGIN} from "../navigation/CONSTANTS";
import {createAxios} from "../api/axios";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import useLogout from "../hooks/useLogout";

export const AxiosContext = createContext(null);
export const AxiosProvider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  const instanceRef = useRef(createAxios());

  useEffect(() => {
    const responseIntercept = instanceRef.current.interceptors.response.use(
      response => response,
      async (error) => {
        let response = error?.response || {};
        let status = response?.status;

        if (status === 401) {
          await logout();
          navigate(ADMIN_LOGIN, {state: location, replace: true});
        }

        return Promise.reject(error);
      }
    )

    return () => {
      instanceRef.current.interceptors.response.eject(responseIntercept);
    }
  }, []);

  return (
    <AxiosContext.Provider value={instanceRef.current}>
      <Outlet />
    </AxiosContext.Provider>
  );
}