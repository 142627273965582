import {useTranslation} from "react-i18next";
import {ApiAutocomplete} from "../../../components/ApiAutocomplete";
import useAxiosAuth from "../../../hooks/useAxiosAuth";

export const AuctionsAutocomplete = ({formik, ...props}) => {
  const {t} = useTranslation();
  const axios = useAxiosAuth()

  const apiSearchData = (search, pagination) => axios.get('/api/auctions', {
    params: {
      search: search,
      page: pagination.page,
      size: pagination.pageSize
    }
  });

  const apiFindOne = (initValue) => axios.get(`/api/auctions/${initValue}`);

  const mapAuctionToOption = (auction) => ({
    title: `${auction.name} (${auction.id})`,
    value: auction.id
  })

  return (
    <>
      <ApiAutocomplete
        fieldName='auction'
        apiFindOne={apiFindOne}
        apiSearchData={apiSearchData}
        label={t('pages.admin.items.assign.auction')}
        formik={formik}
        mapToOption={mapAuctionToOption}
        {...props}
      />
    </>
  );
}