import Box from "@mui/material/Box";
import {useNavigate, useParams} from "react-router-dom";
import {DataTable} from "../../../components/DataTable";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useEffect, useState} from "react";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {ADMIN_AUCTIONS_SELLERS_DETAIL, AUCTION_ID, SELLER_ID} from "../../../navigation/CONSTANTS";
import {usePreferredPagination} from "../../../hooks/usePagination";

const columns = (t, auctionId) => ([
  {
    field: 'id',
    headerName: t('pages.admin.sellers.index.tableCol.id'),
    disableColumnMenu: true,
    width: 20
  },
  {
    field: 'name',
    headerName: t('pages.admin.sellers.index.tableCol.name'),
    disableColumnMenu: true,
    width: 250,
  },
  {
    field: 'email',
    headerName: t('pages.admin.sellers.index.tableCol.email'),
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'reference',
    headerName: t('pages.admin.sellers.index.tableCol.reference'),
    disableColumnMenu: true,
    width: 150,
  },
]);

export const AuctionSellers = () => {
  let {auctionId} = useParams();
  let [sellers, setSellers] = useState([]);
  let [paginationModel, setPaginationModel] = usePreferredPagination('pag_a_sellers');
  let [rowCount, setRowCount] = useState(0);
  let [isLoading, setIsLoading] = useState(true);
  const {t} = useTranslation();
  const axiosAuth = useAxiosAuth();
  const navigate = useNavigate();


  useEffect(async () => {
    try {
      const response = await axiosAuth.get(`/api/auctions/${auctionId}/sellers`,
        {
          params: {
            page: paginationModel.page,
            size: paginationModel.pageSize
          }
        });

      setSellers(response.data?.data);
      setRowCount(response.data?.page.totalCount);
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [paginationModel]);

  let gridOptions = {
    paginationMode: 'server',
    pageSizeOptions: [10, 25, 50, 100],
    initialState: {
      pagination: { paginationModel: paginationModel },
    },
    onPaginationModelChange: setPaginationModel,
    loading: isLoading,
    rowCount,
    onRowClick: function (params) {
      navigate(ADMIN_AUCTIONS_SELLERS_DETAIL.replace(AUCTION_ID, auctionId).replace(SELLER_ID, params.row.id));
    }
  }

  return (
    <>
      <DataTable
        rows={sellers}
        columns={columns(t, auctionId)}
        pagination={paginationModel}
        gridOptions={gridOptions}
        addNew={<Box/>}/>
    </>
  );
}