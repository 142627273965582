import {axiosAuth} from "../../../api/axios";
import {useTranslation} from "react-i18next";
import {ApiAutocomplete} from "../../../components/ApiAutocomplete";

export const SellersAutocomplete = ({formik, initSellerId, ...props}) => {
  const {t} = useTranslation();

  const apiFindOne = (initValue) => axiosAuth.get(`/api/sellers/${initValue}`);

  const apiSearchData = (search, pagination) => axiosAuth.get('/api/users', {
    params: {
      tag: 'SELLER',
      search: search,
      page: pagination.page,
      size: pagination.pageSize
    }
  });

  return (
    <>
      <ApiAutocomplete
        formik={formik}
        label={t('pages.admin.items.add.seller')}
        fieldName='seller'
        initId={initSellerId}
        apiFindOne={apiFindOne}
        apiSearchData={apiSearchData}
        {...props}
      />
    </>
  );
}