export const ROOT = '/';
export const AUCTION_ID = ':auctionId';
export const ITEM_ID = ':itemId';
export const USER_ID = ':userId';
export const SELLER_ID = ':sellerId';


export const ADMIN = '/admin';
export const ADMIN_LOGIN = ADMIN + '/login';

// Items
export const ADMIN_ITEMS = ADMIN + '/items';
export const ADMIN_ITEMS_ADD = ADMIN_ITEMS + '/add';
export const ADMIN_ITEMS_EDIT = ADMIN_ITEMS + '/' + ITEM_ID + '/edit';
export const ADMIN_ITEMS_DETAIL = ADMIN_ITEMS + '/' + ITEM_ID;

// Auctions
export const ADMIN_AUCTIONS = ADMIN + '/auctions';
export const ADMIN_AUCTIONS_ADD = ADMIN_AUCTIONS + '/add'
export const ADMIN_AUCTIONS_DETAIL = ADMIN_AUCTIONS + '/' + AUCTION_ID;
export const ADMIN_AUCTIONS_EDIT = ADMIN_AUCTIONS + '/' + AUCTION_ID + '/edit';

export const ADMIN_AUCTIONS_ITEMS = ADMIN_AUCTIONS_DETAIL + '/items';
export const ADMIN_AUCTIONS_ITEMS_DETAIL = ADMIN_AUCTIONS_ITEMS + '/' + ITEM_ID;
export const ADMIN_AUCTIONS_ITEMS_EDIT = ADMIN_AUCTIONS_ITEMS_DETAIL + '/edit'

export const ADMIN_AUCTIONS_CATEGORIES = ADMIN_AUCTIONS_DETAIL + '/categories';

export const ADMIN_AUCTIONS_INFO = ADMIN_AUCTIONS_DETAIL + '/info';

export const ADMIN_AUCTIONS_SELLERS = ADMIN_AUCTIONS_DETAIL + '/sellers';
export const ADMIN_AUCTIONS_SELLERS_DETAIL = ADMIN_AUCTIONS_SELLERS + '/' + SELLER_ID;

export const ADMIN_AUCTIONS_USERS = ADMIN_AUCTIONS_DETAIL + '/users';
export const ADMIN_AUCTIONS_USERS_DETAIL = ADMIN_AUCTIONS_USERS + '/' + USER_ID;
export const ADMIN_AUCTIONS_USERS_ADD = ADMIN_AUCTIONS_USERS + '/add';
export const ADMIN_AUCTIONS_USERS_EDIT = ADMIN_AUCTIONS_USERS_DETAIL + '/edit';


// Users
export const ADMIN_USERS = ADMIN + '/users';
export const ADMIN_USERS_ADD = ADMIN_USERS + '/add';

//Sellers
export const ADMIN_SELLERS = ADMIN + '/sellers';
export const ADMIN_SELLERS_ADD = ADMIN_SELLERS + '/add';
export const ADMIN_SELLERS_EDIT = ADMIN_SELLERS + '/' + SELLER_ID + '/edit';
export const ADMIN_SELLERS_BATCH_ASSIGN = ADMIN_SELLERS + '/' + SELLER_ID + '/batch-assign';


export const LOGIN = '/login';
export const CATALOG = '/catalog';
export const AUCTION_LIST = '/auction';
export const AUCTION_ITEM_ID = ':auctionItemId';
export const AUCTION_USER_ID = ':auctionUserId';
export const AUCTION_DETAIL = '/auction/' + AUCTION_ID;
export const AUCTION_DETAIL_ITEM_LIST = AUCTION_DETAIL + '/items';
export const AUCTION_DETAIL_ITEM_DETAIL = AUCTION_DETAIL + '/item/' + AUCTION_ITEM_ID;
export const AUCTION_DETAIL_ITEM_NEW = AUCTION_DETAIL_ITEM_LIST + '/new';
export const AUCTION_DETAIL_USER_LIST = AUCTION_DETAIL + '/user';
export const AUCTION_DETAIL_USER_DETAIL = AUCTION_DETAIL + '/user/' + AUCTION_USER_ID;
export const AUCTION_DETAIL_USER_NEW = AUCTION_DETAIL_USER_LIST + '/new';
export const AUCTION_DETAIL_SETTINGS = AUCTION_DETAIL + '/settings';

export const AUCTION_NEW = '/auction/new';


export const AUCTION_LOT_STATE_NEW = 'NEW';
export const AUCTION_LOT_STATE_SOLD = 'SOLD';
export const AUCTION_LOT_STATE_NOT_SOLD = 'NOT_SOLD';
