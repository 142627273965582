import {Box, Button, Chip, Grid, Menu, MenuItem, Typography} from "@mui/material";
import * as React from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";
import {StaticImage} from "../../../components/StaticImage";
import {AUCTION_LOT_STATE_NOT_SOLD, AUCTION_LOT_STATE_SOLD, AUCTION_LOT_STATE_NEW} from "../../../navigation/CONSTANTS";

export const ItemTopPanel = ({item, categories, handleStateChange}) => {
  const {t} = useTranslation();
  const isFullWidth = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  const categoryMap = Object.assign({}, ...categories.map(c => ({[c.id]: c.fullName})));

  return (
    <>
      <Box display="flex" gap={1}>
        <Box>
          <StaticImage
            variant="FRONT"
            photo={item.photo}
            sx={{
              height: '60px',
              width: '60px',
            }}
          />
        </Box>

        <Box flexGrow="1">
          <Box display="flex">
            <Box>
              <Typography variant="h4">{item.lot.name}</Typography>
            </Box>
          </Box>

        </Box>

        <Box>
          <Box sx={{textAlign: "end"}}>
            #{item.customAuctionLotId}
          </Box>
          <Chip label={categoryMap[item.auctionCategoryId] || 'N/A'} sx={{
            height: '26px',
            backgroundColor: '#f5f5f5'
          }}/>
        </Box>
      </Box>

      <Grid container>
        <Grid item sm={12}>
          <Box display="flex" gap={10}>
            <Box>
              <Box component="label" sx={{fontSize: '12px'}}>{t('pages.admin.auctions.items.detail.startPrice')}</Box>
              <Typography variant="h5">{item.startingBid}€</Typography>
            </Box>

            {item.state === AUCTION_LOT_STATE_SOLD &&
              (<Box color='success'>
                <Box component="label" sx={{fontSize: '12px'}}>{t('pages.admin.auctions.items.detail.soldPrice')}</Box>
                <Typography variant="h5" color='green'>{item.winningBid}€</Typography>
              </Box>)
            }

          </Box>
        </Grid>
        <Grid item xs={12} display={ isFullWidth ? "initial" : "flex" } justifyContent="flex-end" sx={{ my: 1, alignItems: "flex-end"}}>
            <CloseAsOption state={item.state} handleStateChange={handleStateChange}/>
        </Grid>
      </Grid>
    </>
  )
}

function CloseAsOption({state, handleStateChange}) {
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const changeState = (state) => {
    handleStateChange(state);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isFullWidth = useMediaQuery((theme) => theme.breakpoints.only("xs"));

  const stateElement = ({
    [AUCTION_LOT_STATE_SOLD] : (<Chip label={t(`general.lot.${AUCTION_LOT_STATE_SOLD}`)} color="success" onDelete={() => changeState(AUCTION_LOT_STATE_NEW)} />),
    [AUCTION_LOT_STATE_NOT_SOLD] : (<Chip label={t(`general.lot.${AUCTION_LOT_STATE_NOT_SOLD}`)} color="error" onDelete={() => changeState(AUCTION_LOT_STATE_NEW)} />),
  })[state] || (
    <Button
      variant="outlined"
      disableElevation
      onClick={handleClick}
      endIcon={<KeyboardArrowDownIcon/>}
      fullWidth={isFullWidth}>
      {t('pages.admin.auctions.items.detail.btnCloseAs')}
    </Button>
  );

  return (
    <div>
      {stateElement}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeState("SOLD")} disableRipple>
          {t('pages.admin.auctions.items.detail.sold')}
        </MenuItem>
        <MenuItem onClick={() => changeState("NOT_SOLD")} disableRipple>
          {t('pages.admin.auctions.items.detail.notSold')}
        </MenuItem>
      </Menu>
    </div>
  );
}