export const withStringIds = (items) => {
  return items?.map(item => ({
    ...item,
    id: item.id.toString()
  })) || [];
}

export const withDefinedFields = (data) => {
  return Object.entries(data)
    .filter(([key, value]) => value !== '')
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
}

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};