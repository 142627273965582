import './App.css';
import 'fontsource-roboto';
import {ThemeProvider} from "@mui/material";
import {JSXRouterConfig} from "./navigation/RouterConfig";
import {createBrowserRouter, createRoutesFromElements, RouterProvider} from "react-router-dom";
import {mainTheme} from "./styles/muiTheme";
import {AuthProvider} from "./context/AuthProvider";

export const BASE_NAME = '/auction'

function App() {
  let router = createBrowserRouter(
      createRoutesFromElements(JSXRouterConfig),
      {
        basename: BASE_NAME
      });

  return (
    <div>
      <ThemeProvider theme={mainTheme}>
        {/* <ThemeSwitch
                    darkState={darkState}
                    handleThemeChange={handleThemeChange}
                />*/}

        <AuthProvider>
          <RouterProvider router={router}/>
        </AuthProvider>

      </ThemeProvider>
    </div>
  )
    ;
}

export default App;
