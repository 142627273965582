import {Fragment} from "react";
import {CircularProgress, TextField} from "@mui/material";

export const LoadingTextField = ({label}) => {

  return (
    <TextField
      disabled
      fullWidth
      label={label}
      InputProps={{
        endAdornment: (
          <Fragment>
            <CircularProgress color="inherit" size={20}/>
          </Fragment>
        ),
      }}/>
  )
}