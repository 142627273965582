import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import {CircularProgress, TextField} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";
import useCategories from "../../../hooks/useCategories";
import {getFormikProps} from "../../../hooks/getFormikProps";
import {useEffect, useState} from "react";
import {LoadingTextField} from "../../../components/LoadingTextField";

const filter = createFilterOptions();

export const CategoriesAutocomplete = ({auctionId, initCategoryId, formik, ...params}) => {
  const {t} = useTranslation();
  const [categories, categoriesLoading] = useCategories(auctionId);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if(categoriesLoading) {
      return;
    }

    if(initCategoryId) {
      onSelect(categories.find(c => c.id === initCategoryId))
    }
  }, [categoriesLoading]);

  const onSelect = (selected) => {
    formik.setFieldValue("itemCategory", selected && selected.id);
    setValue(selected);
  }

  return (
    <>
      {categoriesLoading
        ? <LoadingTextField label={t('pages.admin.items.add.category')} />
        : <Autocomplete
          fullWidth
          value={value}
          noOptionsText={t('components.autoComplete.noOptions')}
          renderInput={(params) =>
            <TextField
              {...params}
              label={t('pages.admin.items.add.category')}
              {...getFormikProps(formik, 'itemCategory')}
            />}
          onChange={(e, value) => onSelect(value)}
          /* TODO: add new category as last option
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                toggleOpen(true);
                setDialogValue({
                  title: newValue,
                  year: '',
                });
              });
            } else if (newValue && newValue.inputValue) {
              toggleOpen(true);
              setDialogValue({
                title: newValue.inputValue,
                year: '',
              });
            } else {
              setValue(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                fullName: `${t('components.autoComplete.addNew')} "${params.inputValue}"`,
              });
            }

            return filtered;
          }}*/

          options={categories}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.fullName;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(props, option) => <li {...props}>{option.fullName}</li>}
          {...params}
        />
      }
    </>
  )
}