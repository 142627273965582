import Box from "@mui/material/Box";
import {DataTable, DataTableActionButton, DataTableActionMenu} from "../../../components/DataTable";
import {
  ADMIN_AUCTIONS_DETAIL, ADMIN_AUCTIONS_ITEMS,
  ADMIN_ITEMS_ADD,
  ADMIN_ITEMS_EDIT,
  AUCTION_ID,
  ITEM_ID,
} from "../../../navigation/CONSTANTS";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {StaticImage} from "../../../components/StaticImage";
import {convertArrayToObject, withDefinedFields, withStringIds} from "../../../utils/idUtils";
import {PageContent} from "../../../components/PageContent";
import {PageHeader} from "../../../components/PageHeader";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {AssignItemToAuctionDialog} from "./AssignItemToAuctionDialog";
import AlertDialogYesNo from "../../../components/AlertDialogYesNo";
import Button from "@mui/material/Button";
import {AddCircle} from "@mui/icons-material";
import {Chip, Divider, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {GlobalItemSearchFilter} from "./GlobalItemSearchFilter";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {usePreferredPagination} from "../../../hooks/usePagination";

const columns = function (t, onAssign, openForSelection, handleOpenMenu, rowSelectionModel, selectedRowData) {
  let columns = [
    {
      field: 'id',
      headerName: t('pages.admin.auctions.items.tableCol.id'),
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'right',
      width: 20,
    },
    {
      field: 'name',
      headerName: t('pages.admin.auctions.items.tableCol.name'),
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="10px">
            <StaticImage
              variant="FRONT"
              photo={params.row.photo}
              sx={{
                height: '40px',
                width: '40px',
              }}
            />
            <Box sx={{lineHeight: '40px'}}>
              <p>{params.row.name}</p>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'ownerReference',
      headerName: t('pages.admin.auctions.items.tableCol.owner'),
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      width: 100
    },
  ];

  if(openForSelection) {
    columns.push({
      field: 'customAuctionLotId',
      headerName: t('pages.admin.auctions.items.tableCol.customAuctionLotId'),
      disableColumnMenu: true,
      editable: true,
      sortable: false,
      width: 100,
      align: 'right',
      renderCell: (params) => {
        let isSelectedRow = rowSelectionModel.includes(params.row.id);
        let selectedRow = selectedRowData[params.row.id] || {};
        let sx = {
          color: isSelectedRow && !selectedRow.customAuctionLotId ? 'red' : 'black'
        }

        return <Box sx={sx}>
          {isSelectedRow && !selectedRow.customAuctionLotId
            ? t('general.buttons.edit')
            : selectedRow.customAuctionLotId }
        </Box>
      }
    });
  } else {
    columns.push({
      field: 'auctionId',
      headerName: t('pages.admin.items.tableCol.auction'),
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {

        const assignToAuctionBtn = (
          <Box display="flex" justifyContent="center" sx={{width: '100%'}}>
            <Link onClick={(evt) => onAssign(params.row)}>
              <DataTableActionButton size="small">
                <AddCircle fontSize="small"/>
              </DataTableActionButton>
            </Link>
          </Box>
        )

        const auctionId = params.row.auction?.id;
        const auctionLotState = params.row.auctionLotState;
        const showAuctionChip = auctionId && auctionLotState !== 'NOT_SOLD';

        const labelStyle = showAuctionChip
          ? {backgroundColor: '#e5f6fd', color: '#014361'}
          : {backgroundColor: '#f5f5f5'};

        const label = showAuctionChip
          ? <Link to={ADMIN_AUCTIONS_DETAIL.replace(AUCTION_ID, auctionId)}
                  style={{'textDecorationColor': labelStyle.color, 'color': labelStyle.color}}
                  title={params.row.auction.name}>
            {`${params.row.auction.name}`}
          </Link>
          : assignToAuctionBtn;

        return (
          showAuctionChip
            ? <Chip label={label} sx={{
              height: '26px',
              width: '100%',
              ...labelStyle
            }}/>
            : assignToAuctionBtn
        )
      }
    },
    {
      field: 'actions',
      headerName: '',
      width: 30,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="center" sx={{width: '100%'}}>
            <DataTableActionButton size="small" onClick={(e) => handleOpenMenu(e, params.row)}>
              <MoreVertIcon fontSize="small" />
            </DataTableActionButton>
          </Box>
        )
      },
    })
  }
  return columns;
}

export const GlobalItems = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {state} = useLocation();
  const openForSelection = !!state?.selectForAuctionId;
  const [paginationModel, setPaginationModel] = usePreferredPagination('pag_glob');
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [items, setItems] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [assignItemToAuction, setAssignItemToAuction] = useState(null);
  const [confirmRemoveLotId, setConfirmRemoveLotId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({});
  const axiosAuth = useAxiosAuth();

  useEffect(() => {
    let isMounted = true;
    if(!isLoading) {
      return;
    }
    const fetchData = async () => {
      try {
        const response = await axiosAuth.get('/api/lots', {
          params: {
            ...searchCriteria,
            notSoldOnly: openForSelection,
            page: paginationModel.page,
            size: paginationModel.pageSize
          }
        });
        isMounted && setItems(withStringIds(response.data?.data || []));
        isMounted && setRowCount(response.data?.page?.totalCount || 0);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [paginationModel]);

  useEffect(() => {
    setIsLoading(true);
  }, [state]);

  const onAssignAuction = (item) => {
    setAssignItemToAuction(item)
  }

  const closeAssignAuctionDialog = () => {
    setAssignItemToAuction(null)
  }

  const refreshList = () => {
    setIsLoading(true);
    closeAssignAuctionDialog();
  }
  const showDeleteAlert = (row) => {
    setConfirmRemoveLotId(row.id);
  }

  const closeDeleteAlert = () => {
    setConfirmRemoveLotId(null);
  }

  const deleteLot = async () => {
    try {
      await axiosAuth.delete(`/api/coins/${confirmRemoveLotId}`);
      closeDeleteAlert();
      setIsLoading(true);
    } catch (err) {
      console.error(err);
    }
  }

  const handleOpenMenu = (e, row) => {
    setAnchorEl({element: e.target, row: row});
    e.stopPropagation();
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleEditActionMenu = (row) => {
    const editPath = ADMIN_ITEMS_EDIT.replace(ITEM_ID, row.id);
    navigate(editPath);
  }

  let gridOptions = {
    paginationMode: 'server',
    pageSizeOptions: [10, 25, 50, 100],
    initialState: {
      pagination: { paginationModel: paginationModel },
    },
    onPaginationModelChange: setPaginationModel,
    loading: isLoading,
    rowCount,
    checkboxSelection: openForSelection,
    keepNonExistentRowsSelected: openForSelection,
    onRowSelectionModelChange: (newRowSelectionModel) => {
      setRowSelectionModel(newRowSelectionModel);
      const selectedIDs = new Set(newRowSelectionModel);
      const unselectedIDs = rowSelectionModel
        .filter((rowId) => !selectedIDs.has(rowId));

      const selectedNewData = items
        .filter((row) => selectedIDs.has(row.id));

      const mergedData = {...convertArrayToObject(selectedNewData, 'id'), ...selectedRowData};
      unselectedIDs.forEach(unselectedId => {
        delete mergedData[unselectedId];
      });

      setSelectedRowData(mergedData);
    },
    isCellEditable: (params) => rowSelectionModel.includes(params.row.id),
    processRowUpdate: (updatedRow, originalRow) => {
      const newRowData = {[updatedRow.id]: updatedRow};
      setSelectedRowData({...selectedRowData, ...newRowData});
      return updatedRow;
    }
  }

  const saveSelection = async () => {
    try {
      let auctionId = state.selectForAuctionId;
      let lots = rowSelectionModel.map(lotId => ({
        lotId: lotId,
        auctionId: auctionId,
        startingBid: 0,
        customAuctionLotId: selectedRowData[lotId].customAuctionLotId
      }));
      await axiosAuth.post(`/api/auctions/${auctionId}/lots`, {lots});
      navigate(ADMIN_AUCTIONS_ITEMS.replace(AUCTION_ID, auctionId), {state: { successfullyAdded: true}, replace: true});
    } catch (err) {
      console.error(err);
    }
  }

  const onSearchCriteriaChange = (change) => {
    setSearchCriteria(withDefinedFields({...searchCriteria, ...change}));
    setIsLoading(true);
  }

  const missingCustomAuctionId = rowSelectionModel
    .filter(id => !selectedRowData[id].customAuctionLotId);

  const addNewButton = (
    <Button variant="contained" onClick={saveSelection} disabled={!rowSelectionModel.length || missingCustomAuctionId.length}>
      {t('general.buttons.add')}
    </Button>
  )

  return (
    <>
      <PageHeader header={t('pages.admin.items.header')}>
      </PageHeader>

      <PageContent>
        <DataTable
          rows={items}
          columns={columns(t, onAssignAuction, openForSelection, handleOpenMenu, rowSelectionModel, selectedRowData)}
          gridOptions={gridOptions}
          pagination={paginationModel}
          addNew={openForSelection && addNewButton}
          addNewLink={ADMIN_ITEMS_ADD}
          disableRowClick>

          <Divider sx={{my: 1}}/>
          <GlobalItemSearchFilter onSearchCriteriaChange={onSearchCriteriaChange}/>
        </DataTable>
      </PageContent>

      {assignItemToAuction &&
        <AssignItemToAuctionDialog
          title={t("pages.admin.items.assign.title")}
          item={assignItemToAuction}
          onClose={closeAssignAuctionDialog}
          onAssignment={refreshList}
        />}


      {confirmRemoveLotId &&
        <AlertDialogYesNo
          title={t('general.modal.delete')}
          content={t('pages.admin.items.remove.dialogText')}
          onYes={deleteLot}
          onNo={closeDeleteAlert}
          onClose={closeDeleteAlert}/>
      }

      {anchorEl &&
        <DataTableActionMenu
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          onEdit={handleEditActionMenu}
          onDelete={showDeleteAlert}
        />
      }
    </>
  );
}