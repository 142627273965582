import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, GlobalStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {BASE_APP_URL} from "../../../api/axios";
import {createSvgIcon} from "@mui/material/utils";

const inputGlobalStyles = <GlobalStyles styles={{
  '@media print': {
    '*': {
      backgroundColor: 'white !important',
    },
    html: {
      height: '100vh',
      margin: '0 !important',
      padding: '0 !important',
    },
    body: {
      height: '100vh',
      visibility: 'hidden',
      margin: '0 !important',
      padding: '0 !important',
    },
    '#root': {
      display: 'none'
    },
    '#printable': {
      visibility: 'visible',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
    }
  }
}}/>;

const tableStyles = {
  'td:not(.blank-cell)': {
    paddingTop: 0,
    'border-bottom': 'none !important',
    'border-bottom-style': 'none',
  }
}

const billWrapperStyles = {
  border: 'solid 1px lightgrey',
  borderRadius: '5px',
  borderStyle: 'dashed',
  padding: '10px'
}

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);

export const BuyersReceiptDialog = ({user, auctionId, receipt, ...props}) => {
  const {t} = useTranslation();

  const printBill = () => {
    const link = document.createElement("a");
    link.download = "report.pdf";
    link.href = `${BASE_APP_URL}/api/auctions/${auctionId}/users/${user.id}/trades/pdf`;
    link.click();

    //one can also use window.print() to print what is displayed!
  }

  return (
    <React.Fragment>
      {inputGlobalStyles}

      <Dialog open={true}
              onClose={props.onClose}
              fullWidth
              maxWidth="lg">
        <DialogTitle>Nahlad blocku</DialogTitle>
        <DialogContent>
          <Box id='printable' sx={billWrapperStyles}>
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <Box>{t('receipt.title')}</Box>
              <Box>{t('receipt.auctionNum', {number: auctionId})}</Box>
              <Box>&nbsp;</Box>
            </Box>
            <Box>{t('receipt.buyer.reference', {number: user.regNumber})}</Box>
            <Box>&nbsp;</Box>

            <TableContainer>
              <Table sx={tableStyles}>
                <TableHead>
                  <TableRow>
                    <TableCell width="8%" align="left">{t('receipt.buyer.lotId')}</TableCell>
                    <TableCell width="60%" align="left">{t('receipt.buyer.lotName')}</TableCell>
                    <TableCell width="8%" align="left">{t('receipt.buyer.startingBid')}</TableCell>
                    <TableCell width="8%" align="left">{t('receipt.buyer.price')}</TableCell>
                    <TableCell width="8%" align="left">{t('receipt.buyer.fee')}</TableCell>
                    <TableCell width="8%" align="left">{t('receipt.buyer.total')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receipt.tradedLots.map((row) => (
                    <TableRow
                      key={row.lotId}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell align="right">{row.lotOrderId}</TableCell>
                      <TableCell>{row.lotName}</TableCell>
                      <TableCell align="right">{row.startingBid?.toFixed(2)}</TableCell>
                      <TableCell align="right">{row.price?.toFixed(2)}</TableCell>
                      <TableCell align="right">
                        {row.actualFee?.buyersPremiumType === 'PERC'
                          ? row.actualFee?.buyersPremium + '%'
                          : row.actualFee?.buyersPremium.toFixed(2)
                        }
                      </TableCell>
                      <TableCell align="right">{row.total?.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}

                  {BlankRow}

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Celkom</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">{receipt.totals.withoutFees?.toFixed(2)}</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">{receipt.totals.withFees?.toFixed(2)}</TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </Box>

        </DialogContent>

        <DialogActions>
          <Button onClick={props.onClose}>{t('general.buttons.cancel')}</Button>
          <Button onClick={printBill} startIcon={<ExportIcon/>}>{t('general.buttons.pdf')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const BlankRow = (
  <TableRow>
    <TableCell className='blank-cell'>&nbsp;</TableCell>
    <TableCell className='blank-cell'/>
    <TableCell className='blank-cell'/>
    <TableCell className='blank-cell'/>
    <TableCell className='blank-cell'/>
    <TableCell className='blank-cell'/>
  </TableRow>
)