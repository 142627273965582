import {Box, Button, Checkbox, Container, FormControlLabel, Paper, TextField, Typography} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useFormik} from "formik";
import {ADMIN} from "../../../navigation/CONSTANTS";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getFormikProps} from "../../../hooks/getFormikProps";
import {useAuth} from "../../../hooks/useAuth";
import useAxiosAuth from "../../../hooks/useAxiosAuth";

export const AdminLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const {setAuth} = useAuth();
  const {t} = useTranslation();
  const axios = useAxiosAuth();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const email = formik.values.email;
        const password = formik.values.password;
        let response = await axios.post('api/auth/login', JSON.stringify({email, password}),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          });

        let userId = response.data?.userId;
        let user = response.data?.userName;
        let role = response.data?.role;
        setAuth({email, userId, user, role});

        let redirectPath = location.state?.from?.pathname || ADMIN;
        navigate(redirectPath, {replace: true});
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return <Container maxWidth='xs'>

    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component='h1' variant='h5' sx={{mb: 1}}>
        {t('pages.admin.login.signIn')}
      </Typography>

      <Paper elevation={1} sx={{px: 4, py: 2, mb: 2}}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id='email'
            name='email'
            label={t('pages.admin.login.email')}
            autoComplete='email'
            margin='normal'
            fullWidth
            required
            autoFocus
            {...getFormikProps(formik, 'email')}
          />
          <TextField
            id='password'
            name='password'
            label={t('pages.admin.login.password')}
            autoComplete='current-password'
            type='password'
            margin='normal'
            fullWidth
            required
            autoFocus
            {...getFormikProps(formik, 'password')}
          />
          <FormControlLabel
            disabled
            control={<Checkbox value='remember' color='primary'/>}
            label='Remember me'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{mt: 3, mb: 2}}
            disabled={isLoading}
          >
            {t('pages.admin.login.signInBtn')}
          </Button>
        </form>
      </Paper>
    </Box>
  </Container>
}