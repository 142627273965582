export function getFormikProps(formik, fieldName) {
  return {
    error: formik.touched[fieldName] && Boolean(formik.errors[fieldName]),
    helperText: formik.touched[fieldName] && formik.errors[fieldName],
    ...formik.getFieldProps(fieldName)
  }
}

export function getDateTimeFormikProps(formik, fieldName) {
  return {
    helperText: formik.touched[fieldName] && formik.errors[fieldName],
    error: formik.touched[fieldName] && Boolean(formik.errors[fieldName]),
    onBlur: () => formik.setFieldTouched(fieldName, true, true),
    onChange: (value) => formik.setFieldValue(fieldName, value, true),
  }
}