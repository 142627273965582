import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {DataTable} from "../../../components/DataTable";
import {useTranslation} from "react-i18next";
import {StaticImage} from "../../../components/StaticImage";
import {withStringIds} from "../../../utils/idUtils";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {AuctionLotStateChip} from "../../../components/AuctionLotState";
import {Button, Paper, Typography} from "@mui/material";
import useUser from "../../../hooks/useUser";
import {SellersReceiptDialog} from "./SellersReceiptDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {usePreferredPagination} from "../../../hooks/usePagination";

const columns = function (t) {
  return [
    {
      field: 'customAuctionLotId',
      headerName: t('pages.admin.auctions.items.tableCol.id'),
      disableColumnMenu: true,
      width: 20,
      align: 'right',
    },
    {
      field: 'name',
      headerName: t('pages.admin.auctions.items.tableCol.name'),
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="10px">
            <StaticImage
              variant="FRONT"
              photo={params.row.photo}
              sx={{
                height: '40px',
                width: '40px',
              }}
            />
            <Box sx={{lineHeight: '40px'}}>
              <p>{params.row.lot.name}</p>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'startingBid',
      headerName: t('pages.admin.auctions.items.tableCol.price'),
      disableColumnMenu: true,
      width: 70,
      sortable: false,
      align: 'right',
    },
    {
      field: 'state',
      headerName: t('pages.admin.auctions.items.tableCol.state'),
      disableColumnMenu: true,
      width: 130,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <AuctionLotStateChip state={params.value}/>
        )
      }
    },
    {
      field: 'winningBid',
      headerName: t('pages.admin.auctions.items.tableCol.soldPrice'),
      disableColumnMenu: true,
      width: 80,
      sortable: false,
      align: 'right',
    },
  ];
}

export const SellerItems = () => {
  const {t} = useTranslation();
  const [paginationModel, setPaginationModel] = usePreferredPagination('pag_s_items');
  const [items, setItems] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const {auctionId, sellerId} = useParams();
  const [seller, isLoadingSeller] = useUser(sellerId);
  const [receiptData, setReceiptData] = useState(null);
  const [loadingReceiptData, setLoadingReceiptData] = useState(null);
  const [user] = useUser(sellerId);
  const axios = useAxiosAuth();

  useEffect(() => {
    setIsLoading(true);
  }, [paginationModel]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        if (!isLoading) {
          return;
        }
        const response = await axios.get(`/api/auctions/${auctionId}/sellers/${sellerId}/lots`, {
          params: {
            page: paginationModel.page,
            size: paginationModel.pageSize
          }
        });
        isMounted && setItems(withStringIds(response.data?.data || []));
        isMounted && setRowCount(response.data?.page?.totalCount || 0);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    }
  }, [isLoading]);

  const loadReceiptData = async () => {
    let isMounted = true;
    try {
      setLoadingReceiptData(true);
      let response = await axios.get(`/api/auctions/${auctionId}/sellers/${sellerId}/trades`);
      isMounted && setReceiptData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      isMounted && setLoadingReceiptData(false);
    }
  }

  const closeReceiptDialog = () => {
    setReceiptData(null);
  }

  let gridOptions = {
    paginationMode: 'server',
    pageSizeOptions: [10, 25, 50, 100],
    initialState: {
      pagination: { paginationModel: paginationModel },
    },
    onPaginationModelChange: setPaginationModel,
    loading: isLoading,
    rowCount
  }

  return (
    <>
      <Paper elevation={0} sx={{px: 4, py: 2, mb: 2}}>
        <Box display="flex" gap={1}>
          <Box>
            <Typography variant="h4">{t("pages.admin.sellers.items.sellerRef")}</Typography>
          </Box>
          <Box>
            {!isLoadingSeller && seller.reference}
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button color='primary' variant='contained' startIcon={<VisibilityIcon/>} onClick={loadReceiptData}>
            {t('pages.admin.sellers.items.receipt')}
          </Button>
        </Box>
      </Paper>

      <DataTable
        rows={items}
        columns={columns(t)}
        gridOptions={gridOptions}
        pagination={paginationModel}
        addNew={<Box/>}
        disableRowClick
      />

      {receiptData &&
        <SellersReceiptDialog
          onClose={closeReceiptDialog}
          user={user}
          auctionId={auctionId}
          receipt={receiptData}
        />
      }
    </>
  );
}