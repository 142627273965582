import {useEffect, useState} from "react";
import {axiosAuth} from "../api/axios";

const useUser = (userId, auctionId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        if (!userId) {
          return;
        }

        const response = auctionId
          ? await axiosAuth.get(`/api/auctions/${auctionId}/users/${userId}`)
          : await axiosAuth.get(`/api/users/${userId}`);
        setUser(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [isLoading])

  return [user, isLoading];
}

export default useUser;