import {Fragment, useEffect, useState} from "react";
import {axiosAuth} from "../../../api/axios";
import {CircularProgress, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {useTranslation} from "react-i18next";
import {getFormikProps} from "../../../hooks/getFormikProps";
import {LoadingTextField} from "../../../components/LoadingTextField";

export const AuctionsAutocomplete = ({formik, initAuctionId, disabled}) => {
  const [open, setOpen] = useState(false);
  const [initValue] = useState(initAuctionId);
  const [loadingInitValue, setLoadingInitValue] = useState(!!initAuctionId)
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationModel] = useState({page: 0, pageSize: 10});
  const {t} = useTranslation();
  let [timeoutToSearch, setTimeoutToSearch] = useState(null);

  useEffect(async () => {
    let isMounted = true;

    if (!loading) {
      return undefined;
    }

    const response = await axiosAuth.get('/api/auctions', {
      params: {
        search: inputValue,
        page: paginationModel.page,
        size: paginationModel.pageSize
      }
    });

    let auctionOptions = (response.data?.data || []).map(mapAuctionToOption);
    if (isMounted) {
      setOptions(auctionOptions);
      setLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!initValue) {
      return;
    }

    const fetchData = async () => {
      const response = await axiosAuth.get(`/api/auctions/${initValue}`);
      const user = response.data;
      if (!user) {
        return;
      }
      const selectOption = mapAuctionToOption(user);
      setOptions([selectOption]);
      onSelect(selectOption);
      setLoadingInitValue(false);
    }

    fetchData();
  }, [initValue])

  const onSelect = (selected) => {
    formik.setFieldValue("auction", selected && selected.value)
    setValue(selected);
  }

  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      setLoading(true);
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (timeoutToSearch) {
      clearTimeout(timeoutToSearch);
    }

    let timeoutHandle = setTimeout(() => {
      setLoading(true);
    }, 700);
    setTimeoutToSearch(timeoutHandle);
  }, [inputValue]);

  const mapAuctionToOption = (auction) => ({
    title: `${auction.name} (id: ${auction.id})`,
    value: auction.id
  })

  const delayRender = initAuctionId && loadingInitValue;
  return (
    <>
      {delayRender
        ? <LoadingTextField label={t('pages.admin.users.add.userAuctionId')}/>
        : <Autocomplete
          name="auction"
          value={value}
          open={open}
          disabled={disabled}
          onOpen={() => {
            !disabled && setOpen(true);
          }}
          onClose={() => {
            !disabled && setOpen(false);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(e, selected) => onSelect(selected)}
          noOptionsText={t('components.autoComplete.noOptions')}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          getOptionLabel={(option) => option.title}
          options={options}
          filterOptions={(x) => x}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={t('pages.admin.users.add.auction')}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}

              {...getFormikProps(formik, 'auction')}
            />
          )}
        />
      }
    </>
  );
}