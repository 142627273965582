import axios from "axios";
import https from 'https'

export const BASE_APP_URL = process.env.REACT_APP_API_URI || 'http://localhost:4000';

let httpsAgent = https.Agent({
  rejectUnauthorized: false
})

export default axios.create({
  baseURL: BASE_APP_URL,
  httpsAgent: httpsAgent
});

export const axiosAuth = axios.create({
  baseURL: BASE_APP_URL,
  httpsAgent: httpsAgent,
  headers: { 'Content-type': 'application/json'},
  withCredentials: true
});

export const createAxios = () => axios.create({
  baseURL: BASE_APP_URL,
  httpsAgent: httpsAgent,
  headers: { 'Content-type': 'application/json'},
  withCredentials: true
});