import {useContext, useEffect, useMemo, useRef} from "react";
import {AxiosContext} from "../context/AxiosProvider";

export const defaultErrorHandling = (error) => {
  let response = error?.response || {};
  let status = response?.status;

  if (status >= 400) {
    if (response.data?.key && response.data?.detail) {
      //show error message
      setTimeout(() => alert(response.data.detail), 1);
      return Promise.reject(error);
    }
  }
}

const axiosWrapper = (axios) => {
  const tryCatch = async (restCall, config) => {
    try {
      return await restCall();
    } catch (e) {
      if(config && config.skipErrorHandling) {
        return Promise.reject(e);
      } else {
        return defaultErrorHandling(e);
      }
    }
  }

  return {
    get: async (url, params, config) => await tryCatch(() => axios.get(url, params, config), config),
    patch: async (url, params, config) => await tryCatch(() => axios.patch(url, params, config), config),
    put: async (url, params, config) => await tryCatch(() => axios.put(url, params, config), config),
    post: async (url, params, config) => await tryCatch(() => axios.post(url, params, config), config),
    delete: async (url, config) => await tryCatch(() => axios.delete(url, config), config)
  }
}

const useAxiosAuth = () => {
  const axiosInstance = useContext(AxiosContext);
  const axiosAuth = useMemo(() => {
    return axiosInstance;
  }, [axiosInstance]);

  return axiosWrapper(axiosAuth);
}

export default useAxiosAuth;