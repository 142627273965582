import {
  Button, CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import * as React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getFormikProps} from "../../../hooks/getFormikProps";
import AlertDialogYesNo from "../../../components/AlertDialogYesNo";
import {ADMIN_AUCTIONS_USERS_DETAIL, AUCTION_ID, USER_ID} from "../../../navigation/CONSTANTS";
import {Link} from "react-router-dom";
import {defaultErrorHandling} from "../../../hooks/useAxiosAuth";


export const ItemBidsPanel = ({axios, auctionId, lotId, disabled}) => {
  const {t} = useTranslation();
  const [bids, setBids] = useState([]);
  const [bestPrice, setBestPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmNonExistingUserBid, setConfirmNonExistingUserBid] = useState(null);
  const formik = useFormik({
    initialValues: {
      userRegNumber: '',
      userBid: '',
      registerNonExistingUser: false
    },
    validationSchema: Yup.object({
      userRegNumber: Yup.string()
        .required(t('general.validations.required')),
      userBid: Yup.number()
        .required(t('general.validations.required')),
    }),
    onSubmit: async (values) => {
      const payload = {
        userRegNumber: values.userRegNumber,
        amount: values.userBid,
        registerNonExistingUser: values.registerNonExistingUser
      };

      try {
        await axios.post(`/api/auctions/${auctionId}/lots/${lotId}/bids`, payload, {skipErrorHandling: true});
        formik.resetForm();
        setConfirmNonExistingUserBid(null);
        setIsLoading(true);
      } catch (e) {
        if(e.response?.status === 404 && e.response?.data?.errorKey === 'user_not_in_auction') {
          setConfirmNonExistingUserBid(payload);
        } else {
          defaultErrorHandling(e);
        }
      }
    }
  });

  useEffect(() => {
    let isMounted = true;
    if (!isLoading) {
      return undefined;
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/auctions/${auctionId}/lots/${lotId}/bids`);
        isMounted && setBids(response.data?.data)
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [lotId, isLoading]);

  useEffect(() => {
    if (bids?.length) {
      setBestPrice(bids[0].amount);
    } else {
      setBestPrice(null);
    }
  }, [bids])

  const removeBid = async (bidId) => {
    try {
      await axios.delete(`/api/auctions/${auctionId}/lots/${lotId}/bids/${bidId}`);
      setIsLoading(true);
    } catch (err) {
      console.error(err);
    }
  }

  const closeNonExistingUserBidAlert = () => {
    setConfirmNonExistingUserBid(null);
  }

  const registerNonExistingUserBid = () => {
    formik.setFieldValue('registerNonExistingUser', true);
    formik.submitForm();
  }

  const isFullWidth = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  const userDetailLink = (row) =>  ADMIN_AUCTIONS_USERS_DETAIL.replace(AUCTION_ID, auctionId).replace(USER_ID, row.userId);
  return (
    <>
      <Typography variant='h4'>{t('pages.admin.auctions.items.detail.recentBids')}</Typography>
      <Box display='flex' justifyContent='center'>
        <Box>
          <Box component="label" sx={{fontSize: '12px'}}>
            {t('pages.admin.auctions.items.detail.currentPrice')}
          </Box>
          <Typography variant='h5'>{bestPrice}€</Typography>
        </Box>
      </Box>

      {isLoading
        ? (<CircularProgress/>)
        : (<TableContainer>
            <Table sx={{width: '100%'}}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('pages.admin.auctions.items.detail.recentBidsColUser')}</TableCell>
                  <TableCell align="left">{t('pages.admin.auctions.items.detail.recentBidsColBid')}</TableCell>
                  <TableCell align="right">{t('pages.admin.auctions.items.detail.recentBidsColAction')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bids.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell><Link to={userDetailLink(row)}>{row.userRegNumber}</Link></TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">
                      <IconButton sx={{my: '-11px'}} onClick={() => removeBid(row.id)} disabled={disabled}>
                        <DeleteIcon></DeleteIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      {!disabled &&
        <>
          <Grid container display="flex" justifyContent="stretch" sx={{mt: 2}} spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                sx={{my: 1}}
                id='userRegNumber'
                name='userRegNumber'
                label={t('pages.admin.auctions.items.detail.userId')}
                InputProps={{startAdornment: <InputAdornment position="start">#</InputAdornment>}}
                {...getFormikProps(formik, 'userRegNumber')}/>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                sx={{my: 1}}
                id='userBid'
                name='userBid'
                label={t('pages.admin.auctions.items.detail.bid')}
                InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                {...getFormikProps(formik, 'userBid')}/>
            </Grid>
          </Grid>

          <Button variant="outlined" fullWidth sx={{my: 1}} onClick={formik.handleSubmit}>
            {t('pages.admin.auctions.items.detail.btnPlaceBid')}
          </Button>
        </>
      }

      {confirmNonExistingUserBid &&
        <AlertDialogYesNo
          title={t('general.modal.areYouSure')}
          content={t('pages.admin.auctions.items.modal.registerNonExistingUserBid')}
          onYes={registerNonExistingUserBid}
          onNo={closeNonExistingUserBidAlert}
          onClose={closeNonExistingUserBidAlert}/>
      }
    </>
  )
}