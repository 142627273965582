import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {DataTable, DataTableActionButton, DataTableActionMenu} from "../../../components/DataTable";
import ChecklistIcon from "@mui/icons-material/Checklist";
import {useTranslation} from "react-i18next";
import {PageHeader} from "../../../components/PageHeader";
import {PageContent} from "../../../components/PageContent";
import {
  ADMIN_SELLERS_ADD,
  ADMIN_SELLERS_BATCH_ASSIGN,
  ADMIN_SELLERS_EDIT,
  SELLER_ID
} from "../../../navigation/CONSTANTS";
import * as React from "react";
import {useEffect, useState} from "react";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {usePreferredPagination} from "../../../hooks/usePagination";

const columns = (t, handleOpenMenu) => ([
  {
    field: 'id',
    headerName: t('pages.admin.sellers.index.tableCol.id'),
    disableColumnMenu: true,
    width: 20
  },
  {
    field: 'name',
    headerName: t('pages.admin.sellers.index.tableCol.name'),
    disableColumnMenu: true,
    width: 250,
  },
  {
    field: 'email',
    headerName: t('pages.admin.sellers.index.tableCol.email'),
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'reference',
    headerName: t('pages.admin.sellers.index.tableCol.reference'),
    disableColumnMenu: true,
    width: 150,
  },
  {
    field: 'actions',
    headerName: '',
    width: 30,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: 'center',
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" sx={{width: '100%'}}>
          <DataTableActionButton size="small" onClick={(e) => handleOpenMenu(e, params.row)}>
            <MoreVertIcon fontSize="small" />
          </DataTableActionButton>
        </Box>
      )
    },
  },
]);

const iconStyle = {color: "darkgrey", mr: 1};

export const Sellers = () => {
  let [sellers, setSellers] = useState([]);
  let [paginationModel, setPaginationModel] = usePreferredPagination('pag_sellers');
  let [rowCount, setRowCount] = useState(0);
  let [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({});
  const navigate = useNavigate();
  const {t} = useTranslation();
  const axiosAuth = useAxiosAuth();

  useEffect(() => {
    setIsLoading(true);
  }, [paginationModel]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!isLoading) {
        return;
      }
      try {
        const response = await axiosAuth.get('/api/users',
          {
            params: {
              tag: 'SELLER',
              page: paginationModel.page,
              size: paginationModel.pageSize
            }
          });

        isMounted && setSellers(response.data?.data);
        isMounted && setRowCount(response.data?.page.totalCount);
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    }
  }, [isLoading]);

  const handleOpenMenu = (e, row) => {
    setAnchorEl({element: e.target, row: row});
    e.stopPropagation();
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleEditActionMenu = (row) => {
    const editPath = ADMIN_SELLERS_EDIT.replace(SELLER_ID, row.id);
    navigate(editPath);
  }

  const handleBatchAssignActionMenu = (row) => {
    const batchAssignPath = ADMIN_SELLERS_BATCH_ASSIGN.replace(SELLER_ID, row.id);
    navigate(batchAssignPath);
  }


  let gridOptions = {
    pageSizeOptions: [10, 25, 50, 100],
    initialState: {
      pagination: { paginationModel: paginationModel },
    },
    onPaginationModelChange: setPaginationModel,
    loading: isLoading,
    rowCount,
  }

  return (
    <>
      <PageHeader header={t('pages.admin.sellers.index.title')}>
      </PageHeader>

      <PageContent>
        <DataTable
          rows={sellers}
          columns={columns(t,handleOpenMenu)}
          addNewLink={ADMIN_SELLERS_ADD}
          pagination={paginationModel}
          gridOptions={gridOptions}
          disableRowClick
        />
      </PageContent>

      {anchorEl &&
        <DataTableActionMenu
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          onEdit={handleEditActionMenu}
        >
          <MenuItem onClick={() => handleBatchAssignActionMenu(anchorEl.row)}>
            <ChecklistIcon sx={iconStyle} fontSize='small'/>
            {t('pages.admin.sellers.batchAssign.menuTitle')}
          </MenuItem>
        </DataTableActionMenu>
      }
    </>
  );
}