import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {MenuItem, Tooltip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {makeStyles, styled} from "@mui/styles";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useState} from "react";


const ResetFilterButton = styled(Button)(({theme}) => ({
  color: 'darkgrey',
  backgroundColor: 'white',
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
  }
}));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',

    '& .MuiFormLabel-root': {
      paddingLeft: '0.75em',
      paddingTop: '0.75em'
    },

    '& .MuiInputBase-root': {
      padding: '0.75em 0.75em 0 0.75em'
    },

    '& .MuiInput-input:focus': {
      backgroundColor: 'white'
    }
  }
}));

export const GlobalItemSearchFilter = ({onSearchCriteriaChange}) => {
  const classes = useStyles();
  const initSearchValues = {
    name: '',
    sellerReference: ''
  }
  const [timeoutHandle, setTimeoutHandle] = useState(null);
  const [fieldValues, setFieldValues] = useState(initSearchValues);
  const {t} = useTranslation();

  const onInputChanged = (e) => {
    setFieldValues({...fieldValues, ...{[e.target.name]: e.target.value}});

    if(timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
    let newTimeoutHandle = setTimeout(() => {
      onSearchCriteriaChange({[e.target.name]: e.target.value});
    }, 700);

    setTimeoutHandle(newTimeoutHandle);
  }

  const resetSearchFields = (e) => {
    setFieldValues(initSearchValues);
    onSearchCriteriaChange(initSearchValues);
  }

  return (
    <Grid container spacing={2} sx={{mb: 2}}>
      <Grid item xs={12} sm={8}>
        <TextField
          fullWidth
          label={t('general.search.name')}
          name='name'
          variant="standard"
          autoComplete='off'
          value={fieldValues['name']}
          placeholder={t('general.search.general')}
          className={classes.root}
          InputLabelProps={{shrink: true}}
          InputProps={{
            sx: {
              fontSize: 'default',
            },
          }}
          onChange={onInputChanged}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label={t('general.search.seller')}
          name='sellerReference'
          variant="standard"
          autoComplete='off'
          value={fieldValues['sellerReference']}
          placeholder={t('general.search.general')}
          className={classes.root}
          InputLabelProps={{shrink: true}}
          InputProps={{
            sx: {
              fontSize: 'default',
            },
          }}
          onChange={onInputChanged}
        />
      </Grid>

      <Grid item xs={12} sm={1} display='flex'>
        <Tooltip title={t('general.search.resetTooltip')}>
          <ResetFilterButton size="small"
                             variant='contained'
                             onClick={resetSearchFields}
                             sx={{minWidth: '40px', flexGrow: '1'}}>
            <CloseIcon fontSize='small'/>
          </ResetFilterButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}