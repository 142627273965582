import {Box, Button, Grid, Typography} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";

export const UserTopPanel = ({user, auctionId, userId}) => {
  const {t} = useTranslation();

  return (
    <>
      <Box display="flex" gap={1}>
        <Box flexGrow="1">
          <Box display="flex">
            <Box>
              <Typography variant="h4">{user.name}</Typography>
            </Box>
          </Box>

        </Box>

        <Box>
          <Box sx={{textAlign: "end"}}>
            #{user.id}
          </Box>
        </Box>
      </Box>

      <Grid container>
        <Grid item sm={10}>
          {user.email}
        </Grid>
      </Grid>

    </>
  )
}
