import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthProvider";
import useRefreshAuth from "../hooks/useRefreshAuth";
import {Outlet} from "react-router-dom";

export const RefreshAuthInfo = () => {
  const { auth } = useContext(AuthContext)
  const [ isLoading, setIsLoading ] = useState(true);
  const refresh = useRefreshAuth();

  useEffect(() => {
    let isMounted = true;
    const refreshUserInfo = async () => {

      try {
        await refresh();
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }

    !auth?.email ? refreshUserInfo() : setIsLoading(false)

    return () => {
      isMounted = false;
    }
  });

  return (
    <>
      {isLoading
        ? <p>Loading...</p>
        : <Outlet />
      }
    </>
  )
}