import {useAuth} from "./useAuth";
import axios from "../api/axios";

const useLogout = () => {
  const { setAuth } = useAuth()

  const logout = () => {
    try {
      setAuth({})
      const response = axios.post('/api/auth/logout', {}, {
        withCredentials:true
      })
    } catch (err) {
      console.error(err)
    }
  }

  return logout;
}

export default useLogout;