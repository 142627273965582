import {PageHeader} from "../../../components/PageHeader";
import {PageContent} from "../../../components/PageContent";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {AddItem} from "./AddItem";

export const AddItemGlobal = () => {

  const {t} = useTranslation()

  return (
    <>
      <PageHeader header={t('pages.admin.items.add.header')}>
      </PageHeader>

      <PageContent>
        <AddItem/>
      </PageContent>
    </>
  )
}
