import * as React from "react";
import {CircularProgress, Grid, Paper} from "@mui/material";
import {ItemTopPanel} from "./ItemTopPanel";
import {Link, useParams} from "react-router-dom";
import {ItemBidsPanel} from "./ItemBidsPanel";
import {ItemLimitsPanel} from "./ItemLimitsPanel";
import Button from "@mui/material/Button";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import useCategories from "../../../hooks/useCategories";
import {
  ADMIN_AUCTIONS_ITEMS_DETAIL,
  AUCTION_ID,
  ITEM_ID,
  AUCTION_LOT_STATE_NEW
} from "../../../navigation/CONSTANTS";
import useAxiosAuth from "../../../hooks/useAxiosAuth";

export const AuctionsItemDetail = () => {
  const {auctionId, itemId} = useParams();
  const [item, setItem] = useState({});
  const [nav, setNav] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [categories] = useCategories(auctionId);
  const {t} = useTranslation()
  const axiosAuth = useAxiosAuth();

  useEffect(() => {
    let isMounted = true;
    if(!isLoading) {
      return;
    }

    const fetchData = async () => {
      const response = await axiosAuth.get(`/api/auctions/${auctionId}/lot`, {
        params: {
          coinOrder: itemId
        }
      });

      if (response.status === 200) {
        isMounted && setItem(response.data?.data);
        isMounted && setNav({prev: response.data?.prev, next: response.data?.next});
      }
      isMounted && setIsLoading(false);
    }

    fetchData();
    return () => {
      isMounted = false;
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [itemId]);

  const handleStateChange = async (newState) => {
    try {
      await axiosAuth.put(`/api/auctions/${auctionId}/lots/${item.lot.id}/state`, {state: newState});
      setIsLoading(true);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end" sx={{mb: 2}}>
        <Grid item>
          <Button variant="contained"
                  component={Link}
                  startIcon={<NavigateBeforeIcon/>}
                  disabled={!nav.prev}
                  to={ADMIN_AUCTIONS_ITEMS_DETAIL.replace(AUCTION_ID, auctionId).replace(ITEM_ID, nav.prev)}>
            {t('general.buttons.prev')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained"
                  component={Link}
                  endIcon={<NavigateNextIcon/>}
                  disabled={!nav.next}
                  to={ADMIN_AUCTIONS_ITEMS_DETAIL.replace(AUCTION_ID, auctionId).replace(ITEM_ID, nav.next)}>
            {t('general.buttons.next')}
          </Button>
        </Grid>
      </Grid>

      {isLoading
        ? (<CircularProgress/>)
        : (<>
            <Paper elevation={1} sx={{px: 4, py: 2, mb: 2}}>
              <ItemTopPanel item={item} categories={categories} handleStateChange={handleStateChange}></ItemTopPanel>
            </Paper>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Paper elevation={1} sx={{px: 4, py: 2, mb: 2}}>
                  <ItemBidsPanel axios={axiosAuth} auctionId={auctionId} lotId={item.lot.id} disabled={item.state !== AUCTION_LOT_STATE_NEW}/>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper elevation={1} sx={{px: 4, py: 2, mb: 2}}>
                  <ItemLimitsPanel auctionId={auctionId} lotId={item.lot.id} disabled={item.state !== AUCTION_LOT_STATE_NEW}/>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
    </>
  )
}