import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {LoadingTextField} from "./LoadingTextField";
import Autocomplete from "@mui/material/Autocomplete";
import {CircularProgress, TextField} from "@mui/material";
import {getFormikProps} from "../hooks/getFormikProps";

export const ApiAutocomplete = ({formik, initId, fieldName, label, mapToOption, apiSearchData, apiFindOne, ...props}) => {
  const [open, setOpen] = useState(false);
  const [initValue] = useState(initId);
  const [loadingInitValue, setLoadingInitValue] = useState(!!initId)
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationModel] = useState({page: 0, pageSize: 10});
  const {t} = useTranslation();
  let [timeoutToSearch, setTimeoutToSearch] = useState(null);

  useEffect(async () => {
    let isMounted = true;

    if (!loading) {
      return undefined;
    }

    const response = await apiSearchData(inputValue, paginationModel);
    let auctionOptions = (response.data?.data || []).map(mapRowToOption);
    if (isMounted) {
      setOptions(auctionOptions);
      setLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!initValue) {
      return;
    }

    const fetchData = async () => {
      const response = await apiFindOne(initValue);
      const user = response.data;
      if (!user) {
        return;
      }
      const selectOption = mapRowToOption(user);
      setOptions([selectOption]);
      onSelect(selectOption);
      setLoadingInitValue(false);
    }

    fetchData();
  }, [initValue])

  const onSelect = (selected) => {
    formik.setFieldValue(fieldName, selected && selected.value)
    setValue(selected);
  }

  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      setLoading(true);
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (timeoutToSearch) {
      clearTimeout(timeoutToSearch);
    }

    let timeoutHandle = setTimeout(() => {
      setLoading(true);
    }, 700);
    setTimeoutToSearch(timeoutHandle);
  }, [inputValue]);

  const mapRowToOption = (item) =>
    mapToOption ? mapToOption(item) :
      {
        title: `${item.name}`,
        value: item.id
      };

  const delayRender = initId && loadingInitValue;
  return (
    <>
      {delayRender
        ? <LoadingTextField label={label}/>
        : <Autocomplete
          fullWidth
          name={fieldName}
          value={value}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(e, selected) => onSelect(selected)}
          noOptionsText={t('components.autoComplete.noOptions')}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          getOptionLabel={(option) => option && option.title}
          options={options}
          filterOptions={(x) => x}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}

              {...getFormikProps(formik, fieldName)}
            />
          )}
          {...props}
        />
      }
    </>
  )
    ;
}