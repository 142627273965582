import {Button, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useTranslation} from "react-i18next";

export const FormWrapper = ({children, onSubmit, submitDisabled, loading}) => {
  const {t} = useTranslation();

  return (
    <form onSubmit={onSubmit}>
      <Paper elevation={1} sx={{px: 4, py: 2, mb: 2}}>
        {children}
      </Paper>
      <Box display='flex' justifyContent='flex-end'>
        <Button type="submit" variant="contained" size="medium"
                disabled={submitDisabled || loading}>
          {t('general.buttons.save')}
        </Button>
      </Box>
    </form>
  )
}