import * as React from "react";
import {Button, IconButton, Menu, MenuItem, Chip, Divider} from "@mui/material";
import {createSearchParams, Link, useNavigate, useParams} from "react-router-dom";
import {
  ADMIN_AUCTIONS_EDIT,
  ADMIN_AUCTIONS_ITEMS_DETAIL, ADMIN_AUCTIONS_ITEMS_EDIT, ADMIN_ITEMS,
  ADMIN_ITEMS_ADD,
  AUCTION_ID,
  ITEM_ID
} from "../../../navigation/CONSTANTS";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GavelIcon from '@mui/icons-material/Gavel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {DataTable, DataTableActionButton, DataTableActionMenu} from "../../../components/DataTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {StaticImage} from "../../../components/StaticImage";
import {withDefinedFields, withStringIds} from "../../../utils/idUtils";
import useCategories from "../../../hooks/useCategories";
import AlertDialogYesNo from "../../../components/AlertDialogYesNo";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {AuctionLotStateChip} from "../../../components/AuctionLotState";
import {ItemSearchFilter} from "./ItemSearchFilter";
import {usePreferredPagination} from "../../../hooks/usePagination";

const columns = function (t, categoryMap, paginationModel, handleOpenMenu) {
  return [
    {
      field: 'customAuctionLotId',
      headerName: t('pages.admin.auctions.items.tableCol.id'),
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'center',
      width: 20
    },
    {
      field: 'name',
      headerName: t('pages.admin.auctions.items.tableCol.name'),
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="10px">
            <StaticImage
              variant="FRONT"
              photo={params.row.photo}
              sx={{
                height: '40px',
                width: '40px',
              }}
            />
            <Box sx={{lineHeight: '40px'}}>
              <p>{params.row.lot.name}</p>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'auctionCategoryId',
      headerName: t('pages.admin.auctions.items.tableCol.category'),
      disableColumnMenu: true,
      width: 200,
      renderCell: (params) => {
        return (
          <Box sx={{
            borderRadius: '16px',
            backgroundColor: '#f5f5f5',
            padding: '3px 12px',
            fontSize: '13px'
          }}>
            {categoryMap[params.value] || 'N/A'}
          </Box>
        )
      }
    },
    {
      field: 'ownerReference',
      headerName: t('pages.admin.auctions.items.tableCol.owner'),
      disableColumnMenu: true,
      width: 100,
    },
    {
      field: 'startingBid',
      headerName: t('pages.admin.auctions.items.tableCol.price'),
      disableColumnMenu: true,
      width: 70,
      sortable: false,
      align: 'right',
    },
    {
      field: 'state',
      headerName: t('pages.admin.auctions.items.tableCol.state'),
      disableColumnMenu: true,
      width: 130,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <AuctionLotStateChip state={params.value}/>
        )
      }
    },
    {
      field: 'winningBid',
      headerName: t('pages.admin.auctions.items.tableCol.soldPrice'),
      disableColumnMenu: true,
      width: 80,
      sortable: false,
      align: 'right',
    },
    {
      field: 'actions',
      headerName: '',
      width: 30,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="center" sx={{width: '100%'}}>
            <DataTableActionButton size="small" onClick={(e) => handleOpenMenu(e, params.row)}>
              <MoreVertIcon fontSize="small" />
            </DataTableActionButton>
          </Box>
        )
      },
    },
  ];
}

export const Items = () => {
  const {t} = useTranslation();
  const [paginationModel, setPaginationModel] = usePreferredPagination('pag_items');
  const [items, setItems] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const {auctionId} = useParams();
  const [categories, categoriesLoading] = useCategories(auctionId);
  const [confirmRemoveLotId, setConfirmRemoveLotId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({});
  const axios = useAxiosAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        if(!isLoading) {
          return;
        }
        const response = await axios.get(`/api/auctions/${auctionId}/lots`, {
          params: {
            ...searchCriteria,
            page: paginationModel.page,
            size: paginationModel.pageSize,
          }
        });
        isMounted && setItems(withStringIds(response.data?.data || []));
        isMounted && setRowCount(response.data?.page?.totalCount || 0);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [auctionId, paginationModel]);

  const onSearchCriteriaChange = (change) => {
    setSearchCriteria(withDefinedFields({...searchCriteria, ...change}));
    setIsLoading(true);
  }

  const showDeleteAlert = (row) => {
    setConfirmRemoveLotId(row.lotId);
  }

  const closeDeleteAlert = () => {
    setConfirmRemoveLotId(null);
  }

  const removeLotFromAuction = async () => {
    try {
      await axios.delete(`/api/auctions/${auctionId}/lots/${confirmRemoveLotId}`);
      closeDeleteAlert();
      setIsLoading(true);
    } catch (err) {
      console.error(err);
    }
  }

  const handleOpenMenu = (e, row) => {
    setAnchorEl({element: e.target, row: row});
    e.stopPropagation();
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleEditActionMenu = (row) => {
    const editPath = ADMIN_AUCTIONS_ITEMS_EDIT
      .replace(AUCTION_ID, row.auctionId)
      .replace(ITEM_ID, row.lotId);

    navigate(editPath);
  }


  let gridOptions = {
    paginationMode: 'server',
    pageSizeOptions: [10, 25, 50, 100],
    initialState: {
      pagination: {paginationModel: paginationModel},
    },
    onPaginationModelChange: setPaginationModel,
    loading: isLoading,
    rowCount,
    onRowClick: function (params) {
      navigate(ADMIN_AUCTIONS_ITEMS_DETAIL
        .replace(AUCTION_ID, params.row.auctionId)
        .replace(ITEM_ID, params.row.customAuctionLotId));
    }
  }

  let categoryMap = Object.assign({}, ...categories.map(c => ({[c.id]: c.fullName})));
  return (
    <>

      <DataTable
        rows={items}
        columns={columns(t, categoryMap, paginationModel, handleOpenMenu)}
        gridOptions={gridOptions}
        pagination={paginationModel}
        addNew={<AddNewAs auctionId={auctionId}/>}
      >
        <Divider sx={{my: 1}}/>
        <ItemSearchFilter
            onSearchCriteriaChange={onSearchCriteriaChange}/>
      </DataTable>

      {confirmRemoveLotId &&
        <AlertDialogYesNo
          title={t('general.modal.delete')}
          content={t('pages.admin.auctions.items.detail.removeFromAuction')}
          onYes={removeLotFromAuction}
          onNo={closeDeleteAlert}
          onClose={closeDeleteAlert}
        />}

      {anchorEl &&
        <DataTableActionMenu
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          onEdit={handleEditActionMenu}
          onDelete={showDeleteAlert}
        />
      }
    </>
  );
}

function AddNewAs({auctionId}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToNew = () => {
    handleClose();
    navigate({
      pathname: ADMIN_ITEMS_ADD,
      search: createSearchParams({
        auctionId: auctionId
      }).toString()
    });
  };

  const navigateToGlobalList = () => {
    handleClose();
    navigate(ADMIN_ITEMS, {state: {selectForAuctionId: auctionId}, replace: true});
  }

  return (
    <div>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon/>}
        sx={{width: '120px'}}
      >
        {t('general.buttons.add')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={navigateToGlobalList} disableRipple>
          {t('general.buttons.fromItems')}
        </MenuItem>
        <MenuItem onClick={navigateToNew} disableRipple>
          {t('general.buttons.new')}
        </MenuItem>
      </Menu>
    </div>
  );
}