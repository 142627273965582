import {forwardRef} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@mui/material";
import {useTranslation} from "react-i18next";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogYesNo({title, content, onYes, onNo, onClose}) {
  const {t} = useTranslation();

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{minWidth: "200px"}}>
        <DialogContentText id="alert-dialog-slide-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo}>{t('components.alertDialogYesNo.no')}</Button>
        <Button onClick={onYes}>{t('components.alertDialogYesNo.yes')}</Button>
      </DialogActions>
    </Dialog>
  );
}