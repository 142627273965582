import AppBar from "@mui/material/AppBar";
import {CircularProgress, Tab, Tabs} from "@mui/material";
import * as React from "react";
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {
  ADMIN_AUCTIONS_CATEGORIES,
  ADMIN_AUCTIONS_INFO,
  ADMIN_AUCTIONS_ITEMS,
  ADMIN_AUCTIONS_SELLERS,
  ADMIN_AUCTIONS_USERS,
  AUCTION_ID
} from "../../../navigation/CONSTANTS";
import {PageHeader} from "../../../components/PageHeader";
import {PageContent} from "../../../components/PageContent";
import {useTranslation} from "react-i18next";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {useEffect, useState} from "react";
import useAxiosAuth from "../../../hooks/useAxiosAuth";


export const AuctionsDetail = () => {
  let {auctionId} = useParams();
  let {pathname} = useLocation();
  let [resolvedPath, setResolvedPath] = useState(null);
  const {t} = useTranslation();
  const axiosAuth = useAxiosAuth();
  const [routes, setRoutes] = useState({
    auctionItemListRoute: ADMIN_AUCTIONS_ITEMS.replace(AUCTION_ID, auctionId),
    auctionSellersRoute: ADMIN_AUCTIONS_SELLERS.replace(AUCTION_ID, auctionId),
    auctionUsersRoute: ADMIN_AUCTIONS_USERS.replace(AUCTION_ID, auctionId),
    auctionSettingsRoute: ADMIN_AUCTIONS_CATEGORIES.replace(AUCTION_ID, auctionId),
    auctionInfoRoute: ADMIN_AUCTIONS_INFO.replace(AUCTION_ID, auctionId)
  });
  const [auctionData, setAuctionData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    let fetchData = async () => {
      try {
        let auctionDetail = await axiosAuth.get(`/api/auctions/${auctionId}`);
        isMounted && setAuctionData(auctionDetail.data);
      } catch (e) {
        isMounted && setAuctionData(null);
        console.error(e);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [auctionId]);

  useEffect(() => {
    let paths = [
      routes.auctionSellersRoute,
      routes.auctionUsersRoute,
      routes.auctionSettingsRoute,
      routes.auctionInfoRoute,
      routes.auctionItemListRoute
    ];

    let pathValue = paths.find(path => pathname.startsWith(path));
    if (!pathValue) {
      navigate(routes.auctionItemListRoute)
    }
    setResolvedPath(pathValue);
  }, [pathname]);

  let header = typeof auctionData === 'undefined'
    ? ''
    : auctionData === null
      ? t('pages.admin.auctions.detail.header', {id: auctionId})
      : t('pages.admin.auctions.detail.header', {id: auctionData.name});

  return (
    <>
      {!resolvedPath
        ? (<CircularProgress/>)
        : (<>
            <PageHeader header={header}>
              <AppBar component="div" position="static" elevation={0} sx={{zIndex: 0}}>
                <Tabs value={resolvedPath} textColor="inherit">
                  <Tab label={t('pages.admin.auctions.detail.tabItems')}
                       value={routes.auctionItemListRoute} to={routes.auctionItemListRoute} component={Link}/>
                  <Tab label={t('pages.admin.auctions.detail.tabSellers')}
                       value={routes.auctionSellersRoute} to={routes.auctionSellersRoute} component={Link}/>
                  <Tab label={t('pages.admin.auctions.detail.tabBuyers')}
                       value={routes.auctionUsersRoute} to={routes.auctionUsersRoute} component={Link}/>
                  <Tab label={t('pages.admin.auctions.detail.tabCategories')}
                       value={routes.auctionSettingsRoute} to={routes.auctionSettingsRoute} component={Link}/>
                  <Tab label={t('pages.admin.auctions.detail.info')}
                       value={routes.auctionInfoRoute} to={routes.auctionInfoRoute} component={Link}/>
                </Tabs>
              </AppBar>
            </PageHeader>

            {/*<Breadcrumbs />*/}

            <PageContent>
              <Outlet />
            </PageContent>
          </>
        )
      }
    </>
  );
}