import {useParams} from "react-router-dom";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import {AddAuction} from "./index";

export const EditAuction = () => {
  const {auctionId} = useParams();
  const axiosAuth = useAxiosAuth();
  const [auctionData, setAuctionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await Promise.all([
          axiosAuth.get(`/api/auctions/${auctionId}`),
          axiosAuth.get(`/api/auctions/${auctionId}/fee`)
        ])

        const auction = response[0].data;
        const fee = response[1].data;

        setAuctionData({...auction, ...fee});
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [auctionId]);

  return (
    <>
      {isLoading
        ? <CircularProgress/>
        : <AddAuction auction={auctionData} />
      }
    </>
  );

}