import {styled} from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Box, CircularProgress, Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const DataTableActionButton = styled(IconButton)(({theme}) => ({
  color: "darkgrey",
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

export const DataTable = (
  {
    columns,
    rows,
    pagination = {page: 0, pageSize: 5},
    gridOptions = {loading: false, pageSizeOptions: [5]},
    styleOverrides = {},
    addNew,
    addNewLink,
    disableRowClick = false,
    children
  }) => {

  const {t} = useTranslation();
  const rowClickStyles = disableRowClick &&
    {
      '& .MuiDataGrid-row:hover': {
        cursor: 'default'
      }
    };

  return (
    <>
      <Box display='flex' justifyContent='end' sx={{mb: 2}}>
        <Box>
          {addNew ? (addNew) : (
            <Button variant="contained" component={Link} to={addNewLink}>
              {t('general.buttons.addNew')}
            </Button>
          )}
        </Box>
      </Box>

      {children}

      <Box>
      {rows.length === 0 && gridOptions.loading
        ? (<Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center"> <CircularProgress/></Typography>)
        : rows.length > 0
          ? (<DataGrid
            autoHeight
            disableRowSelectionOnClick
            rows={rows}
            columns={columns}
            paginationModel={pagination}
            getRowId={(row) => row.id}
            {...gridOptions}
            rowHeight={60}
            sx={{
              ...styleOverrides,
              ...rowClickStyles
            }}
            slotProps={{
              pagination: {
                labelRowsPerPage: t("components.datatable.rowsPerPage")
              }
            }}
          />)

          : (<Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
            {t('components.datatable.noItems')}
          </Typography>)
      }
      </Box>
    </>
  );

}

export function DataTableActionMenu({anchorEl, onClose, onEdit, onDelete, children}) {
  const {t} = useTranslation();

  const _onEdit = () => {
    onEdit(anchorEl.row);
    onClose();
  }

  const _onDelete = () => {
    onDelete(anchorEl.row);
    onClose();
  }

  const iconStyle = {color: "darkgrey", mr: 1};

  return (
    <Menu
      open={true}
      anchorEl={anchorEl.element}
      onClose={onClose}
    >
      {children}

      <MenuItem disabled={!onEdit} onClick={_onEdit}>
        <EditIcon sx={iconStyle} fontSize='small'/>
        {t('components.datatable.editAction')}
      </MenuItem>
      <MenuItem disabled={!onDelete} onClick={_onDelete}>
        <DeleteIcon sx={iconStyle} fontSize='small'/>
        {t('components.datatable.deleteAction')}
      </MenuItem>
    </Menu>
  )
}