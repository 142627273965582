import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, CircularProgress, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import {AddCategoryDialog} from "./AddCategoryDialog";
import {CategoryTree} from "../../../components/CategoryTree";
import * as React from "react";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import AlertDialogYesNo from "../../../components/AlertDialogYesNo";

export const AuctionCategories = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [reloadData, setReloadData] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryForDelete, setSelectedCategoryForDelete] = useState(null);
  const {t} = useTranslation();
  const {auctionId} = useParams();
  const axios = useAxiosAuth();

  useEffect( () => {

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`/api/auction/${auctionId}/categories`);
        const categories = response.data?.data || [];
        categories.sort((a,b) => a.order.localeCompare(b.order));
        setCategoryData(categories);
        setReloadData(false);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    reloadData && fetchData();
  }, [reloadData]);

  const handleOpenCategoryDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setSelectedCategory(null);
    setOpenDialog(false)
  }

  const handleCategoryAdd = async ({isEdit, data}) => {
    if(isEdit) {
      setReloadData(true);
    } else {
      setCategoryData([...categoryData, data]);
    }
    handleCloseCategoryDialog();
  }

  const onMove = async ({categoryId, parentId, prevId, nextId}) => {
    try {
      const updatedCategory = await axios.patch(`/api/auction/${auctionId}/categories/${categoryId}/order`, {
        prevId,
        nextId,
        parentId
      });

      let categoryDataCopy = [...categoryData].map(
        category => category.id === categoryId
          ? updatedCategory.data
          : category
      );
      categoryDataCopy.sort((a,b) => a.order.localeCompare(b.order));

      setCategoryData(categoryDataCopy);
    } catch (err) {
      console.error(err);
    } finally {
    }
  }

  const onEdit = (node) => {
    setSelectedCategory(node);
    setOpenDialog(true);
  }

  const onDelete = (node) => {
    setSelectedCategoryForDelete(node.id);
  }

  const removeCategory = async () => {
    try {
      if(!selectedCategoryForDelete) {
        return;
      }

      await axios.delete(`/api/auction/${auctionId}/categories/${selectedCategoryForDelete}`);
      closeDeleteAlert();
      setReloadData(true);
    } catch (err) {
      console.log(err);
    }
  }

  const closeDeleteAlert = () => {
    setSelectedCategoryForDelete(null);
  }

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" onClick={handleOpenCategoryDialog}>
          {t('general.buttons.addNew')}
        </Button>

        {openDialog && (
          <AddCategoryDialog
            auctionId={auctionId}
            category={selectedCategory}
            categories={categoryData}
            onClose={handleCloseCategoryDialog}
            onAdd={handleCategoryAdd}
          />
        )}

        {selectedCategoryForDelete && (
          <AlertDialogYesNo
            title={t('general.modal.delete')}
            content={t('pages.admin.auctions.categories.removeCategory')}
            onYes={removeCategory}
            onNo={closeDeleteAlert}
            onClose={closeDeleteAlert}
          />
        )}
      </Box>

      {categoryData.length === 0 && isLoading
        ? (<Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center"> <CircularProgress/></Typography>)
        : categoryData.length > 0
          ? (<Paper elevation={1} sx={{mb: 2}}>
            <CategoryTree
              categoryTreeData={categoryData}
              onReorder={onMove}
              onEdit={onEdit}
              onDelete={onDelete}
              sx={{
                '& .MuiTreeItem-label': {
                  height: '52px',
                  lineHeight: '52px'
                },
              }}
            />
          </Paper>)
          : (<Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
            {t('general.list.noRows')}
          </Typography>)}
    </>
  )
}