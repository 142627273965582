import {
  Box,
  Button, CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEffect, useState} from "react";
import {AddLimitDialog} from "./AddLimitDialog";
import {useTranslation} from "react-i18next";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as React from "react";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {
  ADMIN_AUCTIONS_ITEMS_DETAIL,
  ADMIN_AUCTIONS_USERS_DETAIL,
  AUCTION_ID,
  ITEM_ID,
  USER_ID
} from "../../../navigation/CONSTANTS";

export const ItemLimitsPanel = ({auctionId, lotId, disabled}) => {
  const [openLimitDialog, setOpenLimitDialog] = useState(false);
  const [limits, setLimits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const axiosAuth = useAxiosAuth();
  const {t} = useTranslation();
  const formik = useFormik({
    initialValues: {
      userRegNumber: '',
      userLimit: '',
      createdOn: dayjs()
    },
    validationSchema: Yup.object({
      userRegNumber: Yup.string()
        .required(t('general.validations.required')),
      userLimit: Yup.number()
        .required(t('general.validations.required')),
    }),
    onSubmit: async (values) => {
      const response = await axiosAuth.post(`/api/auctions/${auctionId}/lots/${lotId}/bids`, {
        userRegNumber: values.userRegNumber,
        amount: values.userLimit,
        createdOn: values.createdOn,
        execType: 'L'
      });
      formik.resetForm();
      setIsLoading(true);
    }
  });


  useEffect(() => {
    let isMounted = true;
    if (!isLoading) {
      return undefined;
    }
    const fetchData = async () => {
      try {
        const response = await axiosAuth.get(`/api/auctions/${auctionId}/lots/${lotId}/bids`, {
          params: {
            execType: 'L'
          }
        });
        if (isMounted) {
          setLimits(response.data?.data)
        }
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [lotId, isLoading]);

  const removeLimit = async (bidId) => {
    try {
      await axiosAuth.delete(`/api/auctions/${auctionId}/lots/${lotId}/bids/${bidId}`);
      setIsLoading(true);
    } catch (err) {
      console.error(err);
    }
  }

  const handleOpenLimitDialog = () => {
    setOpenLimitDialog(true);
  };

  const handleCloseLimitDialog = () => {
    setOpenLimitDialog(false)
    formik.resetForm();
  }

  const handleLimitSubmit = () => {
    setOpenLimitDialog(false);
    formik.handleSubmit();
  }

  const userDetailLink = (row) =>  ADMIN_AUCTIONS_USERS_DETAIL.replace(AUCTION_ID, auctionId).replace(USER_ID, row.userId);

  return (
    <>
      <Typography variant='h4'>{t('pages.admin.auctions.items.detail.limits.title')}</Typography>

      {isLoading
        ? (<CircularProgress/>)
        : (<TableContainer>
            <Table sx={{width: '100%'}}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('pages.admin.auctions.items.detail.limits.colDate')}</TableCell>
                  <TableCell align="left">{t('pages.admin.auctions.items.detail.limits.colUser')}</TableCell>
                  <TableCell align="left">{t('pages.admin.auctions.items.detail.limits.colLimit')}</TableCell>
                  <TableCell align="right">{t('pages.admin.auctions.items.detail.limits.colAction')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {limits.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell align="left">{dayjs(row.createdOn).format("DD.MM.YYYY hh:mm")}</TableCell>
                    <TableCell><Link to={userDetailLink(row)}>{row.userRegNumber}</Link></TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">
                      <IconButton sx={{my: '-11px'}} onClick={() => {removeLimit(row.id);}} disabled={disabled}>
                        <DeleteIcon></DeleteIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}


      {!disabled &&
        <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
          <Button variant="outlined" fullWidth sx={{mx: {xs: 0, sm: 1}, my: 1}}
                  onClick={handleOpenLimitDialog}>
            {t('pages.admin.auctions.items.detail.limits.btnAddLimit')}
          </Button>
        </Box>
      }

      {openLimitDialog && (
        <AddLimitDialog
          formik={formik}
          onClose={handleCloseLimitDialog}
          onSubmit={handleLimitSubmit}
        />
      )}
    </>
  )
}