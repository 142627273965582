import {Link, Route, Routes} from "react-router-dom";
import {
  ADMIN,
  ADMIN_AUCTIONS,
  ADMIN_AUCTIONS_ADD,
  ADMIN_AUCTIONS_DETAIL,
  ADMIN_AUCTIONS_ITEMS_DETAIL,
  ADMIN_AUCTIONS_CATEGORIES,
  ADMIN_AUCTIONS_USERS,
  ADMIN_AUCTIONS_USERS_DETAIL,
  ADMIN_ITEMS,
  ADMIN_ITEMS_ADD,
  ADMIN_LOGIN,
  ADMIN_USERS,
  ADMIN_USERS_ADD,
  ADMIN_SELLERS,
  ADMIN_SELLERS_ADD,
  ADMIN_ITEMS_EDIT,
  ADMIN_AUCTIONS_EDIT,
  ADMIN_SELLERS_EDIT,
  ADMIN_AUCTIONS_USERS_EDIT,
  ADMIN_AUCTIONS_USERS_ADD,
  ADMIN_AUCTIONS_ITEMS_EDIT,
  ADMIN_AUCTIONS_SELLERS,
  ADMIN_AUCTIONS_SELLERS_DETAIL,
  ADMIN_AUCTIONS_INFO, ADMIN_AUCTIONS_ITEMS, ADMIN_SELLERS_BATCH_ASSIGN
} from "navigation/CONSTANTS";
import {NotFound} from "navigation/NotFound";
import {Auctions} from "../pages/Admin/Auctions";
import {AdminLayout} from "../layouts/AdminLayout";
import {AuctionsDetail} from "../pages/Admin/AuctionsDetail";
import {AuctionCategories} from "../pages/Admin/Category";
import {AddAuction} from "../pages/Admin/AuctionsAdd";
import {Items} from "../pages/Admin/Items";
import {AuctionsUserDetail} from "../pages/Admin/AuctionsUserDetail";
import {AuctionsItemDetail} from "../pages/Admin/AuctionsItemDetail";
import {AdminLogin} from "../pages/Admin/AdminLogin";
import {RequireAuth} from "../components/RequireAuth";
import {RefreshAuthInfo} from "../components/RefreshAuthInfo";
import {Sellers} from "../pages/Admin/Sellers";
import {AddSeller} from "../pages/Admin/Sellers/AddSeller";
import {Buyers} from "../pages/Admin/Buyers";
import {AddBuyer} from "../pages/Admin/Buyers/AddBuyer";
import {AddItem} from "../pages/Admin/Items/AddItem";
import {GlobalItems} from "../pages/Admin/Items/GlobalItems";
import {EditItem} from "../pages/Admin/Items/EditItem";
import {EditAuction} from "../pages/Admin/AuctionsAdd/EditAuction";
import {EditSeller} from "../pages/Admin/Sellers/EditSeller";
import {EditBuyer} from "../pages/Admin/Buyers/EditBuyer";
import {EditAuctionItem} from "../pages/Admin/Items/EditAuctionItem";
import {AuctionSellers} from "../pages/Admin/Sellers/AuctionSellers";
import {SellerItems} from "../pages/Admin/Sellers/SellerItems";
import {AuctionInfo} from "../pages/Admin/AuctionInfo";
import {AddItemGlobal} from "../pages/Admin/Items/AddItemGlobal";
import {BatchAssign} from "../pages/Admin/Sellers/BatchAssign";
import {AxiosProvider} from "../context/AxiosProvider";


export const JSXRouterConfig = (
  <Route path={'/'} element={<AxiosProvider/>}>
    <Route path={ADMIN_LOGIN} element={<AdminLogin/>}/>

    <Route element={<RefreshAuthInfo/>}>
      <Route element={<RequireAuth/>}>

        <Route path={ADMIN} element={<AdminLayout/>}>

          <Route path={ADMIN_ITEMS}>
            <Route index={true} element={<GlobalItems/>}/>
            <Route path={ADMIN_ITEMS_ADD} element={<AddItemGlobal/>}/>
            <Route path={ADMIN_ITEMS_EDIT} element={<EditItem/>}/>
          </Route>

          <Route path={ADMIN_USERS}>
            <Route path={ADMIN_USERS_ADD} element={<AddBuyer/>}/>
          </Route>

          <Route path={ADMIN_SELLERS}>
            <Route index={true} element={<Sellers/>}/>
            <Route path={ADMIN_SELLERS_ADD} element={<AddSeller/>}/>
            <Route path={ADMIN_SELLERS_EDIT} element={<EditSeller/>}/>
            <Route path={ADMIN_SELLERS_BATCH_ASSIGN} element={<BatchAssign/>}/>
          </Route>

          <Route path={ADMIN_AUCTIONS}>
            <Route index={true} element={<Auctions/>}/>
            <Route path={ADMIN_AUCTIONS_ADD} element={<AddAuction/>}/>
            <Route path={ADMIN_AUCTIONS_EDIT} element={<EditAuction/>}/>
            <Route path={ADMIN_AUCTIONS_DETAIL} element={<AuctionsDetail/>} handle={{crumb: (path) => "Auction #" + path.params.auctionId, disabled: true}}>

              <Route path={ADMIN_AUCTIONS_ITEMS} handle={{crumb: () => "Items"}}>
                <Route index={true} element={<Items/>}/>
                <Route path={ADMIN_AUCTIONS_ITEMS_DETAIL} handle={{crumb: (path) => "#" + path.params.itemId}}>
                  <Route index={true} element={<AuctionsItemDetail/>}/>
                  <Route path={ADMIN_AUCTIONS_ITEMS_EDIT} element={<EditAuctionItem/>} handle={{crumb: () => "Edit"}}/>
                </Route>
              </Route>

              <Route path={ADMIN_AUCTIONS_SELLERS} handle={{crumb: () => "Sellers"}}>
                <Route index={true} element={<AuctionSellers/>}/>
                <Route path={ADMIN_AUCTIONS_SELLERS_DETAIL} element={<SellerItems/>} handle={{crumb: (path) => "#" + path.params.sellerId}}/>
              </Route>

              <Route path={ADMIN_AUCTIONS_USERS} handle={{crumb: (path) => "Buyers"}}>
                <Route index={true} element={<Buyers/>}/>
                <Route path={ADMIN_AUCTIONS_USERS_DETAIL} element={<AuctionsUserDetail/>}/>
                <Route path={ADMIN_AUCTIONS_USERS_ADD} element={<AddBuyer/>}/>
                <Route path={ADMIN_AUCTIONS_USERS_EDIT} element={<EditBuyer/>}/>
              </Route>

              <Route path={ADMIN_AUCTIONS_CATEGORIES} handle={{crumb: (path) => "Categories"}}>
                <Route index={true} element={<AuctionCategories/>}/>
              </Route>

              <Route handle={{crumb: (path) => "Info"}}>
                <Route path={ADMIN_AUCTIONS_INFO} element={<AuctionInfo/>}/>
              </Route>
            </Route>
          </Route>

        </Route>

      </Route>
    </Route>

    <Route path={'*'} element={<NotFound/>}/>
  </Route>
);