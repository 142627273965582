import {useEffect, useState} from "react";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {AddItem} from "./AddItem";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import {useLocation, useParams} from "react-router-dom";

export const EditAuctionItem = () => {
  const {state} = useLocation();
  const {auctionId, itemId} = useParams();
  const axiosAuth  = useAxiosAuth()
  const [itemData, setItemData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const detail = await Promise.all([
          axiosAuth.get(`/api/auctions/${auctionId}/lots/${itemId}`),
          axiosAuth.get(`/api/lots/${itemId}/fee`)
        ]);

        const item = detail[0].data
        const itemFee = detail[1].data;

        setItemData({...item, ...item.lot, ...item.coin, ...itemFee});
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [itemId]);

  return (
    <>
      {isLoading
        ? <CircularProgress/>
        : <AddItem item={itemData}
                   saveOrBackLink={state?.onBack}/>
      }
    </>
  )
}