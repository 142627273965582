import {
  Box, Button,
  CircularProgress, Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {useEffect, useState} from "react";
import {BuyersReceiptDialog} from "./BuyersReceiptDialog";

export const UserBidsPanel = ({user, auctionId, userId}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [winningBids, setWinningBids] = useState([]);
  const [loadingBillData, setLoadingBillData] = useState(null);
  const [auctionBillData, setAuctionBillData] = useState(null);
  const {t} = useTranslation();
  const axios = useAxiosAuth();

  useEffect(() => {
    if(!isLoading) {
      return;
    }

    let isMounted = true;
    const fetchData = async () => {
      try {
        let response = await axios.get(`/api/auctions/${auctionId}/users/${userId}/winning-bids`);
        isMounted && setWinningBids(response.data?.data);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    }
  }, [isLoading]);

  const loadReceiptData = async () => {
    let isMounted = true;
    try {
      setLoadingBillData(true);
      let response = await axios.get(`/api/auctions/${auctionId}/users/${userId}/trades`);
      isMounted && setAuctionBillData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      isMounted && setLoadingBillData(false);
    }
  }

  const closeBuyersReceiptDialog = () => {
    setAuctionBillData(null);
  }

  return (
    <>
      <Grid container>
        <Grid item sm={10}>
          <Typography variant='h4'>{t('pages.admin.users.detail.bids.title')}</Typography>
        </Grid>
        <Grid item sm={2}>

        </Grid>
      </Grid>


      {isLoading
        ? (<CircularProgress/>)
        : (<TableContainer>
            <Table sx={{width: '100%'}}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('pages.admin.users.detail.bids.itemCol')}</TableCell>
                  <TableCell align="left">{t('pages.admin.users.detail.bids.startingBidCol')}</TableCell>
                  <TableCell align="left">{t('pages.admin.users.detail.bids.bidCol')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {winningBids.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell>{row.customAuctionLotId} - {row.lotName}</TableCell>
                    <TableCell align="right">{row.startingAmount}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>)
      }

      {!!winningBids.length &&
        <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
          <Button fullWidth variant="outlined" onClick={loadReceiptData} disabled={loadingBillData}>
            {t('pages.admin.users.detail.showBillBtn')}
          </Button>
        </Box>
      }

      {auctionBillData &&
        <BuyersReceiptDialog
          onClose={closeBuyersReceiptDialog}
          user={user}
          auctionId={auctionId}
          receipt={auctionBillData}
        />
      }
    </>
  )
}