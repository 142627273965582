import {Button, Card, CardActions, CircularProgress, Grid} from "@mui/material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SummarizeIcon from '@mui/icons-material/Summarize';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import {makeStyles} from "@mui/styles";
import * as React from "react";
import {BASE_APP_URL} from "../../../api/axios";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const AuctionInfo = () => {
  const {auctionId} = useParams();
  const {t} = useTranslation();

  const downloadAuctioneerPdfReport = `${BASE_APP_URL}/api/auctions/${auctionId}/auctioneer/pdf`;
  const downloadProfitPdfReport = `${BASE_APP_URL}/api/auctions/${auctionId}/profit/pdf`;
  const downloadPublicAuctionPdfReport = `${BASE_APP_URL}/api/auctions/${auctionId}/trades/pdf`;

  return (<>
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        <Card variant="solid" color="primary">
          <CardActions>
            <Button
              fullWidth
              href={downloadAuctioneerPdfReport}
              startIcon={<RecordVoiceOverIcon/>}>
              {t('pages.admin.info.downloadAuctioneerReport')}
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card variant="solid" color="primary">
          <CardActions>
            <Button
              fullWidth
              href={downloadProfitPdfReport}
              startIcon={<TrendingUpIcon/>}>
              {t('pages.admin.info.downloadProfitReport')}
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card variant="solid" color="primary">
          <CardActions>
            <Button
              fullWidth
              href={downloadPublicAuctionPdfReport}
              startIcon={<SummarizeIcon/>}>
              {t('pages.admin.info.downloadPublicAuctionSummaryReport')}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  </>);
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative"
  },
  bottom: {
    color: "lightgray",
    position: "absolute",
    left: 0,
  },
  top: {
    color: "primary",
    animationDuration: "550ms",
    position: "absolute",
    left: 0
  },
  circle: {
    strokeLinecap: "round"
  }
}));

export default function MyCircularProgress(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TrendingUpIcon fontSize="100"/>

      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle
        }}
        size={40}
        thickness={4}
        value={20}
        {...props}
      />
    </div>
  );
}