import {useAuth} from "./useAuth";
import useAxiosAuth from "./useAxiosAuth";

const useRefreshAuth = () => {
  const { setAuth } = useAuth();
  const axiosAuth = useAxiosAuth();

  const refresh = async () => {
    try {
      let authInfo = await axiosAuth.get('/api/auth/info');
      setAuth({
        userId: authInfo.data.id,
        user: authInfo.data.userName,
        email: authInfo.data.email,
        role: authInfo.data.role
      });
    } catch (err) {
      console.error(err);
    }
  }
  return refresh;
}

export default useRefreshAuth;