import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Box, IconButton, Input} from "@mui/material";
import {useTranslation} from "react-i18next";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {styled} from "@mui/styles";
import {BASE_STATIC_URL} from "../api/staticFiles";

const DropzoneRemoveButton = styled(IconButton)(({theme}) => ({
  color: "darkgrey",
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

export const Dropzone = ({onRemove, onAdd, initFiles, sx, variant}) => {

  const baseStyle = {
    flex: 1,
    className: 'dropzone',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '8px'
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    mb: '8px',
    mr: '8px',
    width: 110,
    height: 110,
    p: '4px',
    boxSizing: 'border-box',
    position: 'relative'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  const removeThumb = {
    position: 'absolute',
    right: '0px',
    top: '0px',
    p: 0,

  }

  const {t} = useTranslation();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!initFiles) {
      return;
    }
    const initMap = initFiles
      .filter(file => file.variant === variant)
      .map(file => {
        const fileName = file.url.substring(file.url.lastIndexOf('/') + 1, file.url.length);
        return {
          id: file.lotPhotoId,
          name: fileName,
          handle: `${Math.ceil(Math.random() * 10000)}-${fileName}`,
          preview: BASE_STATIC_URL + file.url,
          variant: file.variant
        }
      });
    setFiles(initMap);
  }, [initFiles])

  const {getRootProps, getInputProps} = useDropzone({
    useFsAccessApi: false,
    accept: {
      'image/*': []
    },
    disabled: files?.length > 0,
    onDrop: acceptedFiles => {
      let newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        handle: `${Math.ceil(Math.random() * 10000)}-${file.name}`,
        variant
      }));
      setFiles([...files, ...newFiles]);
      onAdd(newFiles);
    }
  });

  const removeFile = (fileToRemove) => {
    const newFiles = files.filter(file => file.handle !== fileToRemove.handle);
    setFiles(newFiles);
    onRemove(fileToRemove);
  }

  const thumbs = files.map(file => (
    <Box sx={thumb} key={file.name}>
      <Box sx={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
        <DropzoneRemoveButton sx={removeThumb} size='small' onClick={() => removeFile(file)}>
          <HighlightOffIcon/>
        </DropzoneRemoveButton>
      </Box>
    </Box>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);


  const label = t(`components.dropzone.${variant || "info"}`);
  return (
    <Box className="container" sx={{...sx}}>
      <Box sx={baseStyle} {...getRootProps()}>
        <input {...getInputProps()} />
        <Box>{label}</Box>
      </Box>
      <Box sx={thumbsContainer}>
        {thumbs}
      </Box>
    </Box>
  );
}
