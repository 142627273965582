import {Chip} from '@mui/material';
import {styled} from "@mui/styles";
import {AUCTION_LOT_STATE_SOLD, AUCTION_LOT_STATE_NOT_SOLD, AUCTION_LOT_STATE_NEW} from '../navigation/CONSTANTS'
import {useTranslation} from "react-i18next";

const SuccessChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.success.main,
  backgroundColor: theme.palette.success.light,
  '& .MuiSlider-thumb': {

  },
}));

const ErrorChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.error.light,
}));

const NeutralChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.light,
}));


const chipInput = (t, state) => {
  switch(state) {
    case AUCTION_LOT_STATE_SOLD:
      return <Chip label={t(`general.lot.${state}`)} size='small' color='success'></Chip>;
    case AUCTION_LOT_STATE_NOT_SOLD:
      return <Chip label={t(`general.lot.${state}`)} size='small' color='errorLight'></Chip>;
    default:
      return <Chip label={t(`general.lot.${state}`)} size='small' color='primaryLight'></Chip>;
  }
}

export const AuctionLotStateChip = ({state}) => {
  const {t} = useTranslation();

  return (
    <>
      {chipInput(t, state)}
    </>
  )
}
