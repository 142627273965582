import {useEffect, useState} from "react";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import {AddSeller} from "./AddSeller";

export const EditSeller = () => {
  const {sellerId} = useParams();
  const axiosAuth = useAxiosAuth();
  const [sellerData, setSellerData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responses = await Promise.all([
          axiosAuth.get(`/api/sellers/${sellerId}`),
          axiosAuth.get(`/api/users/${sellerId}/fee`)
        ]);

        const seller = responses[0].data;
        const fee = responses[1].data;
        isMounted && setSellerData({seller, fee});
      } catch (err) {
        console.log(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    }
  },[sellerId]);

  return (
    <>
      {isLoading
        ? <CircularProgress/>
        : <AddSeller seller={sellerData}/>
      }
    </>
  )
}