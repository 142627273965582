import {useParams} from "react-router-dom";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import {AddBuyer} from "./AddBuyer";

export const EditBuyer = () => {
  const {userId, auctionId} = useParams();
  const axiosAuth = useAxiosAuth()
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const response = await axiosAuth.get(`/api/auctions/${auctionId}/users/${userId}`);
          const user = response.data

          setUserData(user);
        } catch (err) {
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      }

      fetchData();
    }, [userId]
  );

  return (
    <>
      {isLoading
        ? <CircularProgress/>
        : <AddBuyer user={userData} />
      }
    </>
  );
}
