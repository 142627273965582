import {createTheme} from '@mui/material/styles';

const light = createTheme({
  palette: {
    mode: 'light'
  },
  typography: {
    subtitle1: {
      fontWeight: 'bold'
    }
  }
});

const dark = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    subtitle1: {
      fontWeight: 'bold'
    }
  }
});

let mainTheme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
    success: {
      light: '#edf7ed',
      main: '#edf7ed',
      dark: '#1e4620',
      contrastText: '#1e4620',
    },
    errorLight: {
      light: '#fdeded',
      main: '#fdeded',
      dark: '#5f2120',
      contrastText: '#5f2120',
    },
    primaryLight: {
      light: '#e5f6fd',
      main: '#e5f6fd',
      dark: '#014361',
      contrastText: '#014361',
    },
    neutral: {
      light: '#f5f5f5'
    }
  },
  typography: {
    fontFamily: 'Open Sans',
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h4: {
      fontWeight: 500,
      fontSize: 17,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

mainTheme = {
  ...mainTheme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        labelSmall: {
          padding: '3px 12px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
        outlined: {
          borderColor: 'rgba(0, 155, 229, 0.2)'
        }
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: mainTheme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: mainTheme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [mainTheme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: mainTheme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: mainTheme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: mainTheme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '2em',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#e3e9ef'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        },
        row: {
          '&:hover': {
            backgroundColor: '#e5f6fd4f',
            cursor: 'pointer'
          }
        },
        cell: {
          '&:focus': {
            outline: 'none'
          },
          '&:focus-within': {
            outline: 'none'
          }
        },
        columnHeaders: {
          backgroundColor: '#f5f5f5',
        },
        columnHeader: {
          backgroundColor: '#f5f5f5',
          '&:focus': {
            outline: 'none'
          },
          '&:focus-within': {
            outline: 'none'
          }
        },
        columnHeaderTitle: {
          fontWeight: 600
        }
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          'MuiCollapse-root': {
            flexGrow: 1,
            borderLeft: '1px dashed #e3e9ef',
            marginLeft: '26px',
          }
        },
        content: {
          paddingLeft: '20px',
          paddingRight: 0,
          borderBottom: '1px solid #e3e9ef',
          '&.Mui-focused': {
            backgroundColor: 'white'
          }
        },
        label: {
          fontSize: '14px',
          marginLeft: '10px'
        }
      }
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          borderLeft: '1px dashed #e3e9ef',
          marginLeft: '26px !important',
        }
      }
    }
  }
}

export {mainTheme, light, dark};
