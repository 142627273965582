import * as React from "react";
import {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField} from "@mui/material";
import {getDateTimeFormikProps, getFormikProps} from "../../../hooks/getFormikProps";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {DateTimePicker} from "@mui/x-date-pickers";

export const AddLimitDialog = ({formik, ...props}) => {
  const {t} = useTranslation();
  const submitEnabled = formik.isValid && formik.dirty;

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>{t('pages.admin.auctions.items.detail.limits.title')}</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            autoComplete="off"
            sx={{mt: 2}}
            fullWidth
            id='userRegNumber'
            name='userRegNumber'
            label={t('pages.admin.auctions.items.detail.userId')}
            InputProps={{startAdornment: <InputAdornment position="start">#</InputAdornment>}}
            {...getFormikProps(formik, 'userRegNumber')}/>

          <TextField
            autoComplete="off"
            sx={{mt: 2}}
            fullWidth
            id='userLimit'
            name='userLimit'
            label={t('pages.admin.auctions.items.detail.limit')}
            InputProps={{startAdornment: <InputAdornment position="start">€</InputAdornment>}}
            {...getFormikProps(formik, 'userLimit')}/>

          <DateTimePicker
            sx={{mt: 2}}
            label={t('pages.admin.auctions.items.detail.limit')}
            name='createdOn'
            value={formik.values.createdOn}
            onChange={(value) => formik.setFieldValue("createdOn", value, true)}
            format={"DD.MM.YYYY HH:mm"}
            slotProps={{
              textField: {
                name: 'createdOn',
                required: true,
                fullWidth: true,
                ...getDateTimeFormikProps(formik, 'createdOn')
              }
            }}
            ampm={false}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('general.buttons.cancel')}</Button>
        <Button
          disabled={!submitEnabled}
          onClick={() =>
            props.onSubmit()
          }
        >
          {t('general.buttons.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}