import {Navigate, Outlet} from "react-router-dom";
import {ADMIN_LOGIN} from "../navigation/CONSTANTS";
import {useAuth} from "../hooks/useAuth";


export const RequireAuth = () => {
  const { auth } = useAuth();

  console.log(JSON.stringify(auth));
  return (
    <>
      {auth?.email
        ? <Outlet/>
        : <Navigate to={ADMIN_LOGIN} />
      }
    </>
  )
}