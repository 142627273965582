import {useState} from "react";
import {useTranslation} from "react-i18next";
import {PageHeader} from "../../../components/PageHeader";
import {PageContent} from "../../../components/PageContent";
import {FormWrapper} from "../../../components/FormWrapper";
import {FormSection} from "../../../components/FormSection";
import {InputAdornment, TextField} from "@mui/material";
import * as React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {ADMIN_SELLERS} from "../../../navigation/CONSTANTS";
import {useNavigate} from "react-router-dom";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {getFormikProps} from "../../../hooks/getFormikProps";

export const AddSeller = ({seller}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isEdit = !!seller;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();

  const initSeller = (seller?.seller || {});
  const initFee = (seller?.fee || {});
  const formik = useFormik({
    initialValues: {
      sellerName: initSeller.name || '',
      sellerEmail: initSeller.email || '',
      sellerTel: initSeller.phone || '',
      sellerRef: initSeller.reference || '',
      sellerCommission: initFee.sellersCommission || 0,
    },
    validationSchema: Yup.object({
      sellerName: Yup.string()
        .required(t('general.validations.required')),
      sellerCommission: Yup.number()
        .required(t('general.validations.required')),
      sellerRef: Yup.string()
        .required(t('general.validations.required'))
    }),
    onSubmit: async (values) => {
      let isMounted = true;
      try {
        setIsLoading(true);
        const payload = JSON.stringify({
          name: formik.values.sellerName,
          email: formik.values.sellerEmail,
          phone: formik.values.sellerTel,
          reference: formik.values.sellerRef,
          commission: formik.values.sellerCommission,
        });
        isEdit
          ? await axiosAuth.put(`/api/sellers/${initSeller.id}`, payload)
          : await axiosAuth.post('/api/sellers', payload);

        navigate(ADMIN_SELLERS)
        isMounted = false;
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }
  });

  const editOrAddTitle = isEdit
    ? t('pages.admin.sellers.edit.header')
    : t('pages.admin.sellers.add.header');

  return (
    <>
      <PageHeader header={editOrAddTitle}>
      </PageHeader>

      <PageContent>
        <FormWrapper onSubmit={formik.handleSubmit} submitDisabled={!formik.isValid || !formik.dirty} loading={isLoading}>
          <FormSection name={t('pages.admin.sellers.add.basicInformation')}>
            <TextField
              fullWidth
              required
              id='sellerName'
              name='sellerName'
              label={t('pages.admin.sellers.add.userName')}
              {...getFormikProps(formik, 'sellerName')}/>
            <TextField
              fullWidth
              id='sellerEmail'
              name='sellerEmail'
              label={t('pages.admin.sellers.add.email')}
              {...getFormikProps(formik, 'sellerEmail')}/>
            <TextField
              fullWidth
              id='sellerTel'
              name='sellerTel'
              label={t('pages.admin.sellers.add.phone')}
              {...getFormikProps(formik, 'sellerTel')}/>
            <TextField
              fullWidth
              required
              id='sellerRef'
              name='sellerRef'
              label={t('pages.admin.sellers.add.ref')}
              {...getFormikProps(formik, 'sellerRef')}/>
          </FormSection>

          <FormSection name={t('pages.admin.users.add.auctionLimits')}>
            <TextField
              fullWidth
              required
              id='sellerCommission'
              name='sellerCommission'
              InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
              InputLabelProps={{shrink: true}}
              label={t('pages.admin.sellers.add.commission')}
              type='number'
              {...getFormikProps(formik, 'sellerCommission')}/>
          </FormSection>
        </FormWrapper>
      </PageContent>
    </>
  )
}