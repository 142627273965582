import {PageHeader} from "../../../components/PageHeader";
import * as React from "react";
import {useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {FormSection} from "../../../components/FormSection";
import {FormWrapper} from "../../../components/FormWrapper";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {ADMIN_AUCTIONS_USERS, ADMIN_AUCTIONS_USERS_DETAIL, AUCTION_ID, USER_ID} from "../../../navigation/CONSTANTS";
import {getFormikProps} from "../../../hooks/getFormikProps";
import {AuctionsAutocomplete} from "./AuctionsAutocomplete";


export const AddBuyer = ({user}) => {
  const {auctionId} = useParams();
  const isEdit = !!user;
  const axiosAuth = useAxiosAuth();
  const navigate = useNavigate();

  const limitList = [
    {
      itemId: "10",
      limit: 150,
    }
  ];
  let [userLimits, setUserLimits] = useState(limitList);
  const {t} = useTranslation();

  const init = user || {}
  const formik = useFormik(
    {
      initialValues: {
        auctionId: auctionId,
        registrationNumber: init.regNumber || '',
        userName: init.name || '',
        userEmail: init.email || '',
        userTel: init.phone || '',
        address: init.address || '',
      },
      validationSchema: Yup.object({
        userName: Yup.string()
          .required(t('general.validations.required')),
        registrationNumber: Yup.string()
          .required(t('general.validations.required')),
      }),
      onSubmit: async (values) => {
        const payload = JSON.stringify({
          auctionId: values.auctionId,
          registrationNumber: values.registrationNumber,
          name: values.userName,
          email: values.userEmail,
          phone: values.userTel,
          address: values.address,
        });

        let response = isEdit
          ? await axiosAuth.put(`/api/buyers/${user.id}`, payload)
          : await axiosAuth.post('/api/buyers', payload)

        isEdit
          ? navigate(ADMIN_AUCTIONS_USERS.replace(AUCTION_ID, auctionId))
          : navigate(ADMIN_AUCTIONS_USERS_DETAIL.replace(AUCTION_ID, auctionId).replace(USER_ID, response.data.id));
      }
    }
  )

  const editOrAddTitle = isEdit
    ? t('pages.admin.users.add.editTitle')
    : t('pages.admin.users.add.title');
  return (
    <>
      <FormWrapper onSubmit={formik.handleSubmit} submitDisabled={!(formik.isValid && formik.dirty)} loading={formik.isSubmitting}>
        <FormSection name={t('pages.admin.users.add.basicInformation')}>
          <AuctionsAutocomplete
            fullWidth
            id='auctionId'
            name='auctionId'
            label={t('pages.admin.users.add.userAuctionId')}
            initAuctionId={auctionId}
            disabled={!!auctionId}
            formik={formik}
          />
          <TextField
            required
            fullWidth
            autoComplete='off'
            id='registrationNumber'
            name='registrationNumber'
            label={t('pages.admin.users.add.registrationNumber')}
            {...getFormikProps(formik, 'registrationNumber')}/>
          <TextField
            required
            fullWidth
            autoComplete='off'
            id='userName'
            name='userName'
            label={t('pages.admin.users.add.userName')}
            {...getFormikProps(formik, 'userName')}/>
          <TextField
            fullWidth
            autoComplete='off'
            id='userEmail'
            name='userEmail'
            label={t('pages.admin.users.add.email')}
            {...getFormikProps(formik, 'userEmail')}/>
          <TextField
            fullWidth
            autoComplete='off'
            id='userTel'
            name='userTel'
            label={t('pages.admin.users.add.phone')}
            {...getFormikProps(formik, 'userTel')}/>
        </FormSection>

        <FormSection name={t('pages.admin.users.add.address')}>
          <TextField
            fullWidth
            multiline
            rows={4}
            id='userAddress'
            name='userAddress'
            label={t('pages.admin.users.add.address')}
            {...getFormikProps(formik, 'address')}/>
        </FormSection>

        {/* TODO: review if this is really needed
          <FormSection name={t('pages.admin.users.add.auctionLimits')}>
            <LimitTable limits={userLimits}>

            </LimitTable>
          </FormSection>
        */}
      </FormWrapper>
    </>
  )
}