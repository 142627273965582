import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import {Menu, MenuItem} from "@mui/material";
import useLogout from "../hooks/useLogout";

const lightColor = 'rgba(255, 255, 255, 0.7)';

function Header(props) {
  const logout = useLogout();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isAvatarMenuOpen = Boolean(anchorEl);
  const avatarMenuHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const avatarMenuHandleClose = () => {
    setAnchorEl(null);
  };

  const {onDrawerToggle} = props;

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{display: {md: 'none', xs: 'block'}}} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon/>
              </IconButton>
            </Grid>

            <Grid item xs/>

            <Grid item>
              <Link
                href="/"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Go to docs
              </Link>
            </Grid>

            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon/>
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <IconButton color="inherit" sx={{p: 0.5}} onClick={avatarMenuHandleClick}>
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar"/>
              </IconButton>

              <Menu
                id="avatar menu"
                anchorEl={anchorEl}
                open={isAvatarMenuOpen}
                onClose={avatarMenuHandleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem disabled onClick={avatarMenuHandleClose}>My account</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
