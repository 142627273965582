import {Box, Button, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useTranslation} from "react-i18next";
import {getFormikProps} from "../../../hooks/getFormikProps";


export const AuctionTable = ({fieldName, formik}) => {
  const {t} = useTranslation();
  let auctionPrices = formik.values[fieldName];

  const handleAdd = () => {
    let lastPrice = auctionPrices[auctionPrices.length - 1];
    formik.setFieldValue(fieldName, [...auctionPrices, {from: +lastPrice.to + 1, to: '', priceStep: ''}])
  }

  let handleRemove = (idx) => {
    formik.setFieldValue(fieldName, (auctionPrices.filter((elem, elemIdx) => elemIdx !== idx)));
  }

  let auctionTableRow = auctionPrices.map((elem, idx) => (
    <Box display='flex'
         justifyContent='space-between'
         key={idx}
         sx={{
           '& .MuiTextField-root': {mb: 0},
         }}>
      <TextField
        sx={{flexGrow: 3, m: 1, ml: 0}}
        autoComplete='off'
        type='number'
        name='from'
        label={t('pages.admin.auctions.add.auctionTable.from')}
        {...getFormikProps(formik, `${fieldName}[${idx}].from`)}
        InputLabelProps={{shrink: true}}
        InputProps={{readOnly: true,}}/>

      <TextField
        sx={{flexGrow: 3, m: 1}}
        autoComplete='off'
        type='number'
        name='to'
        label={t('pages.admin.auctions.add.auctionTable.until')}
        {...getFormikProps(formik, `${fieldName}[${idx}].to`)}/>

      <TextField
        sx={{flexGrow: 3, m: 1}}
        name='priceStep'
        type='number'
        autoComplete='off'
        label={t('pages.admin.auctions.add.auctionTable.priceStep')}
        {...getFormikProps(formik, `${fieldName}[${idx}].priceStep`)}/>

      <Button size='small'
              variant='outlined'
              sx={{m: 1, mr: 0, mb: 0}}
              disabled={idx === 0}
              onClick={(e) => handleRemove(idx)}>
        <DeleteOutlineIcon/>
      </Button>
    </Box>
  ));

  return (
    <Box>
      {auctionPrices.length && (
        <div>
          {auctionTableRow}
        </div>
      )}

      <Box display='flex' justifyContent='flex-end'>
        <Button size='small'
                variant='outlined'
                sx={{m: 1, mr: 0, height: '54px'}}
                onClick={handleAdd}>
          <AddIcon/>
        </Button>
      </Box>

    </Box>
  )
}