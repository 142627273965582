import Box from "@mui/material/Box";
import * as React from "react";
import {PageHeader} from "../../../components/PageHeader";
import {PageContent} from "../../../components/PageContent";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link, useNavigate} from "react-router-dom";
import {
  ADMIN_AUCTIONS_ADD,
  ADMIN_AUCTIONS_DETAIL,
  ADMIN_AUCTIONS_EDIT, ADMIN_AUCTIONS_ITEMS, ADMIN_AUCTIONS_ITEMS_DETAIL, ADMIN_AUCTIONS_ITEMS_EDIT, ADMIN_ITEMS_EDIT,
  AUCTION_ID, ITEM_ID
} from "../../../navigation/CONSTANTS";
import {DataTable, DataTableActionButton, DataTableActionMenu} from "../../../components/DataTable";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next"
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import AlertDialogYesNo from "../../../components/AlertDialogYesNo";
import dayjs from "dayjs"
import {Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {usePreferredPagination} from "../../../hooks/usePagination";


const columns = function (t, handleOpenMenu) {
  return [
    {
      field: 'id',
      headerName: t('pages.admin.auctions.index.tableCol.id'),
      disableColumnMenu: true,
      width: 20
    },
    {
      field: 'startDate',
      headerName: t('pages.admin.auctions.index.tableCol.startFrom'),
      disableColumnMenu: true,
      width: 150,
      valueFormatter: params => dayjs(params?.value).format("DD.MM.YYYY hh:mm"),
    },
    {
      field: 'name',
      headerName: t('pages.admin.auctions.index.tableCol.name'),
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      width: 30,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="center" sx={{width: '100%'}}>
            <DataTableActionButton size="small" onClick={(e) => handleOpenMenu(e, params.row)}>
              <MoreVertIcon fontSize="small" />
            </DataTableActionButton>
          </Box>
        )
      },
    },
  ];
}

export const Auctions = () => {
  let [auctions, setAuctions] = useState([]);
  let [paginationModel, setPaginationModel] = usePreferredPagination('pag_auc');
  let [rowCount, setRowCount] = useState(0);
  let [isLoading, setIsLoading] = useState(true);
  let [confirmRemoveAuctionId, setConfirmRemoveAuctionId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const {t} = useTranslation();
  const axiosAuth = useAxiosAuth();
  const navigate = useNavigate();


  useEffect(() => {
    let isMounted = true;
    if (!isLoading) {
      return;
    }
    const fetchData = async () => {
      try {
        const response = await axiosAuth.get('/api/auctions',
          {
            params: {
              page: paginationModel.page,
              size: paginationModel.pageSize
            }
          });

        isMounted && setAuctions(response.data?.data);
        isMounted && setRowCount(response.data?.page.totalCount);
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [paginationModel])

  const showDeleteAlert = (row) => {
    setConfirmRemoveAuctionId(row.id);
  }

  const closeDeleteAlert = () => {
    setConfirmRemoveAuctionId(null);
  }

  const deleteAuction = async () => {
    try {
      await axiosAuth.delete(`/api/auctions/${confirmRemoveAuctionId}`);
      closeDeleteAlert();
      setIsLoading(true);
    } catch (err) {
      console.error(err);
    }
  }

  const handleOpenMenu = (e, row) => {
    setAnchorEl({element: e.target, row: row});
    e.stopPropagation();
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleEditActionMenu = (row) => {
    const editPath = ADMIN_AUCTIONS_EDIT.replace(AUCTION_ID, row.id);
    navigate(editPath);
  }


  let gridOptions = {
    paginationMode: 'server',
    pageSizeOptions: [10, 25, 50, 100],
    initialState: {
      pagination: { paginationModel: paginationModel },
    },
    onPaginationModelChange: setPaginationModel,
    loading: isLoading,
    rowCount,
    onRowClick: function (params) {
      navigate(ADMIN_AUCTIONS_ITEMS.replace(AUCTION_ID, params.row.id));
    }
  }

  return (
    <>
      <PageHeader header={t('pages.admin.auctions.index.header')}>
      </PageHeader>

      <PageContent>
        <DataTable
          rows={auctions}
          columns={columns(t, handleOpenMenu)}
          addNewLink={ADMIN_AUCTIONS_ADD}
          pagination={paginationModel}
          gridOptions={gridOptions}/>
      </PageContent>

      {confirmRemoveAuctionId &&
        <AlertDialogYesNo
          title={t('general.modal.delete')}
          content={t('pages.admin.auctions.index.removeAuction')}
          onYes={deleteAuction}
          onNo={closeDeleteAlert}
          onClose={closeDeleteAlert}/>
      }

      {anchorEl &&
        <DataTableActionMenu
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          onEdit={handleEditActionMenu}
          onDelete={showDeleteAlert}
        />
      }
    </>
  );
}