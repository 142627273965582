import {Grid, Typography} from "@mui/material";

export const FormSection = ({name, visible=true, children}) => {

    return (
        <>
          {visible &&
            <Grid container direction='row' spacing={2} sx={{
                py: 3,
                '& .MuiFormControl-root': {mb: '24px'},
            }}>
                <Grid item sm={12}>
                    <Typography variant='h4'>{name}</Typography>
                </Grid>
                <Grid item sm={12}>
                    {children}
                </Grid>
            </Grid>
          }
        </>
    );
}