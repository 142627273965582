import {useEffect, useState} from "react";

const usePreferredPagination = (paginationKey = 'pagination') => {
  const [pagination, setPagination] = useState({page: 0, pageSize: +(localStorage.getItem(paginationKey) || 10)});

  useEffect(() => {
    if(localStorage.getItem(paginationKey)) {
      let storedPagination = localStorage.getItem(paginationKey);
      if(+storedPagination !== pagination.pageSize) {
        localStorage.setItem(paginationKey, String(pagination.pageSize));
      }
    } else {
      localStorage.setItem(paginationKey, String(pagination.pageSize));
    }
  }, [pagination]);

  return [pagination, setPagination];
}

export {
  usePreferredPagination
};