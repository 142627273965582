import {useTranslation} from "react-i18next";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide, TextField
} from "@mui/material";
import {forwardRef, useEffect} from "react";
import {AuctionsAutocomplete} from "./AuctionsAutocomplete";
import {useFormik} from "formik";
import {CategoriesAutocomplete} from "./CategoriesAutocomplete";
import * as Yup from "yup";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import * as React from "react";
import {StaticImage} from "../../../components/StaticImage";
import Box from "@mui/material/Box";
import {getFormikProps} from "../../../hooks/getFormikProps";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AssignItemToAuctionDialog = ({onClose, onAssignment, title, item}) => {
  const {t} = useTranslation();
  const axios = useAxiosAuth();
  const formik = useFormik({
    initialValues: {
      auction: '',
      customAuctionLotId: '',
      itemCategory: '',
      startingBid: ''
    },
    validationSchema: Yup.object({
      auction: Yup.number()
        .required(t('general.validations.required')),
      customAuctionLotId: Yup.number()
        .required(t('general.validations.required')),
      itemCategory: Yup.number()
        .required(t('general.validations.required')),
      startingBid: Yup.number()
        .required(t('general.validations.required')),
    }),
    onSubmit: async values => {
      try {
        await axios.post(`/api/lots/${item.id}/auction`, {
          auctionId: values.auction,
          customAuctionLotId: values.customAuctionLotId,
          auctionCategoryId: values.itemCategory,
          startingBid: values.startingBid
        });

        onAssignment();
      } catch (err) {
        console.error(err);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('itemCategory', null);
  }, [formik.values.auction]);

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{width: '400px'}}>
        <Box display="flex" flexDirection="row" gap="10px" sx={{mb: 2, p: 2,
          borderRadius: '8px',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#e3e9ef'
        }}>
          <StaticImage
            variant="FRONT"
            photos={item.photos}
            sx={{
              height: '40px',
              width: '40px',
            }}
          />
          <Box sx={{lineHeight: '40px'}}>
            <p>{item.name}</p>
          </Box>
        </Box>

        <AuctionsAutocomplete
          formik={formik}
          sx={{my: 1}}
        />

        <TextField
          fullWidth
          required
          id='customAuctionLotId'
          name='customAuctionLotId'
          label={t('pages.admin.items.add.customAuctionLotId')}
          type='number'
          sx={{mt: 2}}
          {...getFormikProps(formik, 'customAuctionLotId')}/>

        <CategoriesAutocomplete
          formik={formik}
          auctionId={formik.values.auction}
          disabled={!formik.values.auction}
          sx={{mt: 2}}
        />

        <TextField
          fullWidth
          required
          id='startingBid'
          name='startingBid'
          label={t('pages.admin.items.add.startingBid')}
          type='number'
          sx={{mt: 2}}
          {...getFormikProps(formik, 'startingBid')}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('general.buttons.cancel')}</Button>
        <Button onClick={formik.handleSubmit} disabled={!formik.dirty || !formik.isValid}>
          {t('general.buttons.save')}
          {formik.isSubmitting && (<CircularProgress size={20}/>)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}