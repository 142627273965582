import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {TreeItem} from "@mui/lab";
import TreeView from "@mui/lab/TreeView";
import React, {useState} from "react";
import {Box, Button, CircularProgress, Grid, IconButton, Paper} from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from "@mui/icons-material/Edit";
import {DataTableActionButton, DataTableActionMenu} from "./DataTable";
import {useTranslation} from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import {ADMIN_AUCTIONS_EDIT, AUCTION_ID} from "../navigation/CONSTANTS";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const shortColStyle = {textAlign: 'center', width: "80px"};


export const CategoryTree = ({categoryTreeData, onReorder, onEdit, onDelete, ...props}) => {
  let [selectedId, setSelectedId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const {t} = useTranslation();

  let nodesIndex = {
    root: {
      children: []
    },
    ...categoryTreeData.reduce((acc, curr) => {
      curr.children = [];
      return (acc[curr.id] = curr, acc);
    }, {})
  };

  let flatTreeOrdered = categoryTreeData.map(node => {
    let parentNode = nodesIndex[node.parentId]
      ? nodesIndex[node.parentId]
      : nodesIndex.root

    parentNode.children = [...parentNode.children, node];
    return nodesIndex[node.id];
  });

  let treeRootNodes = flatTreeOrdered.filter((elem) => !elem.parentId)

  function getNewState(nodesIndex) {
    return nodesIndex.root.children.reduce((acc, curr) => {
      return [...acc, ...flattenRecursively(curr)]
    }, []);
  }

  function flattenRecursively(node) {
    let children = node.children || [];
    let flatChildren = children.reduce((acc, curr) => {
      return [...acc, ...flattenRecursively(curr)]
    }, []);

    return [{id: node.id, parentId: node.parentId, name: node.name, order: node.order}, ...flatChildren];
  }

  function moveCategoryUp(node, index) {
    if (!node || index === 0) {
      return;
    }
    let childrenWrap = node.parentId
      ? nodesIndex[node.parentId].children
      : nodesIndex.root.children;

    // calculate future position
    let prevNode = childrenWrap[index - 2];
    let nextNode = childrenWrap[index - 1]
    let prevId = prevNode?.id;
    let nextId = nextNode?.id;
    let parentId = prevNode?.parentId || nextNode?.parentId;
    //[childrenWrap[index], childrenWrap[index - 1]] = [childrenWrap[index - 1], childrenWrap[index]];

    setSelectedId(node.id.toString());
    //onReorder(getNewState(nodesIndex));
    onReorder({categoryId: node.id, parentId, prevId, nextId});
  }

  function moveCategoryDown(node, index) {
    let childrenWrap = node.parentId
      ? nodesIndex[node.parentId].children
      : nodesIndex.root.children;

    if (index === childrenWrap.length - 1) {
      return;
    }
    // calculate future position
    let prevId = childrenWrap[index + 1]?.id;
    let nextId = childrenWrap[index + 2]?.id;
    //[childrenWrap[index], childrenWrap[index + 1]] = [childrenWrap[index + 1], childrenWrap[index]];

    setSelectedId(node.id.toString());
    onReorder({categoryId: node.id, parentId: node.parentId, prevId, nextId});
    //onReorder(getNewState(nodesIndex));
  }

  return <>
    <Box>
      <Box display="flex" justifyContent="flex-end" sx={{
              paddingLeft: '20px',
              height: '52px',
              lineHeight: '52px',
              fontWeight: 500,
              fontSize: '14px',
              backgroundColor: '#f5f5f5',
              borderBottom: '1px solid #e3e9ef'
            }}>
        <Box flexGrow="1">{t('pages.admin.auctions.categories.tableCol.name')}</Box>
        <Box flexGrow="0" sx={shortColStyle}>{t('pages.admin.auctions.categories.tableCol.order')}</Box>
        <Box flexGrow="0" sx={shortColStyle}></Box>
      </Box>
      <TreeView
        selected={selectedId}
        disableSelection={true}
        defaultCollapseIcon={<ExpandMoreIcon sx={{
          border: '1px solid',
          borderRadius: '10px',
          width: '18px',
        }}/>}
        defaultExpandIcon={<ChevronRightIcon sx={{
          border: '1px solid',
          borderRadius: '10px',
          width: '18px',
        }}/>}
        defaultEndIcon={<ChevronRightIcon sx={{
          border: '1px solid',
          borderRadius: '10px',
          width: '18px',
          color: '#e3e9ef',
        }}/>}
        {...props}
      >
        <CategoryNodesWrapper nodes={treeRootNodes}
                              onItemMoveUp={moveCategoryUp}
                              onItemMoveDown={moveCategoryDown}
                              onEdit={onEdit}
                              onDelete={onDelete}
                              {...props}/>
      </TreeView>
    </Box>
  </>
}

const CategoryNodesWrapper = ({nodes, onItemMoveUp, onItemMoveDown, onEdit, onDelete}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (e, row) => {
    setAnchorEl({element: e.target, row: row});
    e.stopPropagation();
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleEditActionMenu = (row) => {
    onEdit(row.node);
    handleCloseMenu();
  }

  const handleDeleteActionMenu = (row) => {
    onDelete(row.node);
    handleCloseMenu();
  }

  return (
    <>
      <CategoryNodes nodes={nodes}
                     onItemMoveUp={onItemMoveUp}
                     onItemMoveDown={onItemMoveDown}
                     onActionMenu={handleOpenMenu}/>

      {anchorEl &&
        <DataTableActionMenu
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          onEdit={handleEditActionMenu}
          onDelete={handleDeleteActionMenu}
        />
      }
    </>
  )
}

const CategoryNodes = ({nodes, onItemMoveUp, onItemMoveDown, onActionMenu}) => {
  return <>
    {nodes.map((node, idx) => (
      <TreeItem className="CategoryTreeItem-root"
                nodeId={node.id.toString()}
                key={node.id}
                label={<ClickableLabel
                  node={(node)}
                  index={idx}
                  onItemMoveUp={onItemMoveUp}
                  onItemMoveDown={onItemMoveDown}
                  onActionMenu={onActionMenu}
                />}>

        {node.children && node.children.length > 0 && (
          <CategoryNodes nodes={node.children}
                         onItemMoveUp={onItemMoveUp}
                         onItemMoveDown={onItemMoveDown}
                         onActionMenu={onActionMenu}/>)
        }
      </TreeItem>
    ))}
  </>
}

const ClickableLabel = ({node, index, onItemMoveUp, onItemMoveDown, onActionMenu}) => {
  let [hover, setHover] = useState(false);

  function moveUp(evt, node, index) {
    evt.stopPropagation();
    onItemMoveUp(node, index);
  }

  function moveDown(evt, node, index) {
    evt.stopPropagation();
    onItemMoveDown(node, index);
  }

  return (
    <Box display="flex"
         justifyContent="flex-end"
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         sx={{
           height: '52px',
           lineHeight: '52px',
         }}

    >
      <Box flexGrow="1">{node.name}</Box>
      <Box flexGrow="0" sx={shortColStyle}>
        <DataTableActionButton size="small" sx={{height: "30px", width: "30px"}}
                               onClick={(evt) => moveUp(evt, node, index)}>
          <ArrowDropUpIcon/>
        </DataTableActionButton>

        <DataTableActionButton size="small"
                               sx={{height: "30px", width: "30px"}}
                               onClick={(evt) => moveDown(evt, node, index)}>
          <ArrowDropDownIcon/>
        </DataTableActionButton>
      </Box>

      <Box flexGrow="0" sx={shortColStyle}>
        <DataTableActionButton size="small" onClick={(e) => onActionMenu(e, {node, index})}>
          <MoreVertIcon fontSize="small" />
        </DataTableActionButton>
      </Box>
    </Box>

  );
}


