import {useEffect, useState} from "react";
import {
  Box,
  Button, CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {AddLimitDialog} from "./AddLimitDialog";
import {useTranslation} from "react-i18next";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import * as React from "react";
import AlertDialogYesNo from "../../../components/AlertDialogYesNo";
import dayjs from "dayjs";
import {useFormik} from "formik";
import * as Yup from "yup";

export const UserLimitsPanel = ({auctionId, userId}) => {
  const {t} = useTranslation();
  const [limits, setLimits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openLimitDialog, setOpenLimitDialog] = useState(false);
  const [confirmRemoveLimitHandle, setConfirmRemoveLimitHandle] = useState(null);
  const axios = useAxiosAuth();
  const formik = useFormik({
    initialValues: {
      lotOrderId: '',
      userLimit: '',
      createdOn: dayjs()
    },
    validationSchema: Yup.object({
      lotOrderId: Yup.string()
        .required(t('general.validations.required')),
      userLimit: Yup.number()
        .required(t('general.validations.required')),
    }),
    onSubmit: async (values) => {
      try {
        await axios.post(`/api/auctions/${auctionId}/users/${userId}/bids`, {
          lotOrderId: values.lotOrderId,
          amount: values.userLimit,
          execType: 'L',
          createdOn: values.createdOn
        });
        formik.resetForm();
        setIsLoading(true);
      } catch (err) {
        console.error(err);
      }
    }
  });

  useEffect(() => {
    if(!isLoading) {
      return;
    }

    let isMounted = true;
    const fetchData = async () => {
      try {
        let response = await axios.get(`/api/auctions/${auctionId}/users/${userId}/bids`, {
          params: {
            execType: 'L'
          }
        });
        isMounted && setLimits(response.data?.data);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    }
  }, [isLoading])

  const deleteLimit = async () => {
    try {
      let {lotId, bidId} = confirmRemoveLimitHandle;
      await axios.delete(`/api/auctions/${auctionId}/lots/${lotId}/bids/${bidId}`);
      closeDeleteAlert();
      setIsLoading(true);
    } catch (err) {
      console.error(err);
    }
  }

  const closeDeleteAlert = () => {
    setConfirmRemoveLimitHandle(null);
  }

  const showDeleteLimitAlert = (lotId, bidId) => {
    setConfirmRemoveLimitHandle({lotId, bidId});
  }

  const handleOpenLimitDialog = () => {
    setOpenLimitDialog(true);
  };

  const handleCloseLimitDialog = () => {
    setOpenLimitDialog(false)
  }

  const handleLimitSubmit = async () => {
    setOpenLimitDialog(false);
    formik.handleSubmit();
  }

  return (
    <>
      <Typography variant='h4'>{t('pages.admin.users.detail.limits.title')}</Typography>

      {isLoading
        ? (<CircularProgress/>)
        : (<TableContainer>
            <Table sx={{width: '100%'}}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('pages.admin.users.detail.limits.dateCol')}</TableCell>
                  <TableCell align="left">{t('pages.admin.users.detail.limits.itemCol')}</TableCell>
                  <TableCell align="left">{t('pages.admin.users.detail.limits.limitCol')}</TableCell>
                  <TableCell align="right">{t('pages.admin.users.detail.limits.actionCol')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {limits.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell align="left">{dayjs(row.createdOn).format("DD.MM.YYYY hh:mm")}</TableCell>
                    <TableCell>{row.customAuctionLotId} - {row.lotName}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">
                      <IconButton sx={{my: '-11px'}} onClick={() => {showDeleteLimitAlert(row.lotId, row.id);}} >
                        <DeleteIcon></DeleteIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>)
      }

      <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
        <Button fullWidth variant="outlined" sx={{m: 1}} onClick={handleOpenLimitDialog}>
          {t('pages.admin.users.detail.limits.addLimitBtn')}
        </Button>
      </Box>

      {openLimitDialog && (
        <AddLimitDialog
          onClose={handleCloseLimitDialog}
          onSubmit={handleLimitSubmit}
          formik={formik}
        />
      )}

      {confirmRemoveLimitHandle &&
        <AlertDialogYesNo
          title={t('general.modal.delete')}
          content={t('general.modal.areYouSure')}
          onYes={deleteLimit}
          onNo={closeDeleteAlert}
          onClose={closeDeleteAlert}/>
      }
    </>
  )
}