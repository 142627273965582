import { useState } from "react";
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput, TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getDateTimeFormikProps, getFormikProps} from "../../../hooks/getFormikProps";
import * as React from "react";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Box from "@mui/material/Box";

export const AddLimitDialog = ({formik, ...props}) => {
  const {t} = useTranslation();

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>{t("pages.admin.users.detail.limits.addLimitHeader")}</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            fullWidth
            required
            autoComplete="off"
            sx={{mt: 2}}
            id='lotOrderId'
            name='lotOrderId'
            label={t("pages.admin.users.detail.limits.lotOrderId")}
            InputProps={{startAdornment: <InputAdornment position="start">#</InputAdornment>}}
            {...getFormikProps(formik, 'lotOrderId')}/>

          <TextField
            fullWidth
            required
            autoComplete="off"
            sx={{mt: 2}}
            id='userLimit'
            name='userLimit'
            label={t("pages.admin.users.detail.limits.amount")}
            InputProps={{startAdornment: <InputAdornment position="start">€</InputAdornment>}}
            {...getFormikProps(formik, 'userLimit')}/>

          <DateTimePicker
            sx={{mt: 2}}
            label={t('pages.admin.users.detail.limits.createdOn')}
            name='createdOn'
            value={formik.values.createdOn}
            onChange={(value) => formik.setFieldValue("createdOn", value, true)}
            format={"DD.MM.YYYY HH:mm"}
            slotProps={{
              textField: {
                name: 'createdOn',
                required: true,
                fullWidth: true,
                ...getDateTimeFormikProps(formik, 'createdOn')
              }
            }}
            ampm={false}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          disabled={formik.disabled}
          onClick={() =>
            props.onSubmit()
          }
        >
          {t("general.buttons.add")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}