import {PageHeader} from "../../../components/PageHeader";
import * as React from "react";
import {InputAdornment, MenuItem, TextField} from "@mui/material";
import {FormSection} from "../../../components/FormSection";
import {FormWrapper} from "../../../components/FormWrapper";
import {PageContent} from "../../../components/PageContent";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SellersAutocomplete} from "./SellersAutocomplete";
import {CategoriesAutocomplete} from "./CategoriesAutocomplete";
import {Dropzone} from "../../../components/Dropzone";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getFormikProps} from "../../../hooks/getFormikProps";
import {
  ADMIN_AUCTIONS_DETAIL, ADMIN_AUCTIONS_ITEMS,
  ADMIN_ITEMS,
  AUCTION_ID
} from "../../../navigation/CONSTANTS";
import useAxiosAuth from "../../../hooks/useAxiosAuth";


export const AddItem = ({item, saveOrBackLink}) => {
  const {t} = useTranslation()
  const isEdit = !!item;
  const [searchParams] = useSearchParams();
  const [auctionId] = useState(isEdit ? item.auctionId : searchParams.get('auctionId'));
  const [files, setFiles] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();

  const onFileRemoved = (fileToRemove, formik) => {
    if (fileToRemove && fileToRemove.id) {
      setFilesToRemove([...filesToRemove, fileToRemove.id]);
    }

    let filesToKeep = files.filter(file => file.handle !== fileToRemove.handle);
    setFiles(filesToKeep);
    formik.setFieldValue('files', filesToKeep);
  }

  const onFilesAdded = (newFiles, formik) => {
    const finalFiles = [...files, ...newFiles];
    setFiles(finalFiles);
    formik.setFieldValue('files', finalFiles);
  }

  function prepareFormData(values) {
    const coinData = {
      'auctionId': auctionId,
      'auctionCategoryId': values.itemCategory,
      'ownerUserId': values.seller,
      'name': values.itemName,
      'description': values.itemDescription,
      'notes': values.itemNotesSecret,
      'denomination': values.itemDenomination,
      'nominal': values.itemNominal,
      'year': values.itemYear,
      'country': values.itemCountry,

      'quality': values.itemQuality,
      'rareness': values.itemRareness,
      'variation': values.itemVariation,
      'material': values.itemMaterial,
      'weight': values.itemWeight,
      'diameter': values.itemDiameter,
      'thickness': values.itemThickness,

      'customAuctionLotId': values.customAuctionLotId,
      'startingBid': values.startingBid,
      'buyersPremium': values.buyersPremium,
      'sellersCommission': values.sellersCommission,
    };
    const photoMeta = {uploadMetadata: [], removePhotoIds: filesToRemove};
    const formData = new FormData();
    (files || []).forEach(fileToUpload => {
      formData.append('photos', fileToUpload);
      photoMeta.uploadMetadata.push({
        variant: fileToUpload.variant,
        name: fileToUpload.name
      });
    });

    formData.append('coin', new Blob([JSON.stringify(coinData)], {type: "application/json"}));
    formData.append('photosMeta', new Blob([JSON.stringify(photoMeta)], {type: "application/json"}));
    return formData;
  }

  const init = (item || {})
  const formik = useFormik({
    initialValues: {
      auctionId: !!auctionId,
      seller: init.ownerUserId,
      itemCategory: init.auctionCategoryId,
      itemName: init.name || '',
      itemNominal: init.nominal || '',
      itemDenomination: init.denomination || '',
      itemYear: init.year || '',
      itemDescription: init.description || '',
      itemNotesSecret: init.notes || '',
      itemVariation: init.variation || '',
      itemMaterial: init.material || '',
      itemWeight: init.weight || '',
      itemDiameter: init.diameter || '',
      itemThickness: init.thickness || '',
      itemCountry: init.country || '',
      itemQuality: init.quality || '',
      itemRareness: init.rareness || '',
      customAuctionLotId: init.customAuctionLotId || '',
      startingBid: init.startingBid || '',
      buyersPremium: init.buyersPremium || '',
      sellersCommission: init.sellersCommission || '',
    },
    validationSchema: Yup.object({
      auctionId: Yup.boolean(),
      seller: Yup.number()
        .required(t('general.validations.required')),
      itemName: Yup.string()
        .required(t('general.validations.required')),
      startingBid: Yup.number()
        .when('auctionId', {
          is: true,
          then: () => Yup.number().required(t('general.validations.required'))
        }),
      customAuctionLotId: Yup.number()
        .when('auctionId', {
          is: true,
          then: () => Yup.number().required(t('general.validations.required'))
        }),
    }),
    onSubmit: async (values) => {
      let isMounted = true;
      const formData = prepareFormData(values);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      try {
        setIsLoading(true);
        isEdit
          ? await axiosAuth.put(`/api/coins/${item.id}`, formData, config)
          : await axiosAuth.post('/api/coins', formData, config);


        isMounted = false;
        navigateToList();
      } catch (e) {
        console.error(e);
      } finally {
        isMounted && setIsLoading(false);
      }
    }
  });

  function navigateToList() {
    if (saveOrBackLink) {
      navigate(saveOrBackLink);
    } else if (auctionId) {
      navigate(ADMIN_AUCTIONS_ITEMS.replace(AUCTION_ID, auctionId));
    } else {
      navigate(ADMIN_ITEMS);
    }
  }

  return (
    <>
      <FormWrapper onSubmit={formik.handleSubmit} submitDisabled={!(formik.isValid && formik.dirty)}
                   loading={isLoading}>
        <FormSection name={t('pages.admin.items.add.sellerInfo')}>
          <SellersAutocomplete
            required
            fullWidth
            id="seller"
            name="seller"
            initSellerId={item?.ownerUserId}
            formik={formik}
          />

        </FormSection>
        <FormSection name={t('pages.admin.items.add.basicInfo')}>
          <TextField
            required
            fullWidth
            id='itemName'
            name='itemName'
            inputProps={{maxLength: 128}}
            label={t('pages.admin.items.add.name')}
            {...getFormikProps(formik, 'itemName')}/>
          <TextField
            fullWidth
            autoComplete='off'
            id='itemNominal'
            name='itemNominal'
            type='number'
            label={t('pages.admin.items.add.nominal')}
            {...getFormikProps(formik, 'itemNominal')}/>
          <TextField
            fullWidth
            autoComplete='off'
            id='itemDenomination'
            name='itemDenomination'
            label={t('pages.admin.items.add.denomination')}
            {...getFormikProps(formik, 'itemDenomination')}/>
          <TextField
            fullWidth
            autoComplete='off'
            type='number'
            id='itemYear'
            name='itemYear'
            label={t('pages.admin.items.add.year')}
            {...getFormikProps(formik, 'itemYear')}/>

          <TextField
            fullWidth
            autoComplete='off'
            id="itemCountry"
            name="itemCountry"
            label={t('pages.admin.items.add.country')}
            {...getFormikProps(formik, 'itemCountry')}>
          </TextField>

          {auctionId &&
            (<CategoriesAutocomplete
                id='itemCategory'
                name='itemCategory'
                auctionId={auctionId}
                initCategoryId={item?.auctionCategoryId}
                formik={formik}
              />
            )
          }

          <TextField
            fullWidth
            id='itemDescription'
            name='itemDescription'
            autoComplete='off'
            label={t('pages.admin.items.add.description')}
            multiline
            rows={4}
            {...getFormikProps(formik, 'itemDescription')}/>

          <TextField
            fullWidth
            multiline
            rows={4}
            autoComplete='off'
            id='itemNotesSecret'
            name='itemNotesSecret'
            label={t('pages.admin.items.add.notes')}
            {...getFormikProps(formik, 'itemNotesSecret')}/>
        </FormSection>

        <FormSection name='Additional info'>
          <TextField
            fullWidth
            select
            id="itemQuality"
            name="itemQuality"
            label={t('pages.admin.items.add.quality')}
            {...getFormikProps(formik, 'itemQuality')}
          >
            <MenuItem value={'1'}>00</MenuItem>
            <MenuItem value={'2'}>PROOF</MenuItem>
            <MenuItem value={'3'}>1/2</MenuItem>
          </TextField>

          <TextField
            fullWidth
            select
            type="text"
            id="itemRareness"
            name="itemRareness"
            label={t('pages.admin.items.add.rareness')}
            InputProps={{
              id: "itemRareness"
            }}
            {...getFormikProps(formik, 'itemRareness')}
          >
            <MenuItem value={'R'}>R</MenuItem>
            <MenuItem value={'RR'}>RR</MenuItem>
            <MenuItem value={'RRR'}>RRR</MenuItem>
          </TextField>

          <TextField
            fullWidth
            autoComplete='off'
            id='itemVariation'
            name='itemVariation'
            label={t('pages.admin.items.add.variation')}
            {...getFormikProps(formik, 'itemVariation')}/>

          <TextField
            fullWidth
            autoComplete='off'
            id='itemMaterial'
            name='itemMaterial'
            label={t('pages.admin.items.add.material')}
            {...getFormikProps(formik, 'itemMaterial')}/>

          <TextField
            fullWidth
            autoComplete='off'
            id='itemWeight'
            name='itemWeight'
            type='number'
            label={t('pages.admin.items.add.weight')}
            {...getFormikProps(formik, 'itemWeight')}/>
          <TextField
            fullWidth
            autoComplete='off'
            id='itemDiameter'
            name='itemDiameter'
            type='number'
            label={t('pages.admin.items.add.diameter')}
            {...getFormikProps(formik, 'itemDiameter')}/>
          <TextField
            fullWidth
            autoComplete='off'
            id='itemThickness'
            name='itemThickness'
            type='number'
            label={t('pages.admin.items.add.thickness')}
            {...getFormikProps(formik, 'itemThickness')}/>
        </FormSection>

        <FormSection name={t('pages.admin.items.add.photo')}>
          <Dropzone
            sx={{mb: '24px'}}
            variant={'FRONT'}
            onAdd={(files) => onFilesAdded(files, formik)}
            onRemove={(file) => onFileRemoved(file, formik)}
            initFiles={init.photos}
          />

          <Dropzone
            variant={'BACK'}
            onAdd={(files) => onFilesAdded(files, formik)}
            onRemove={(file) => onFileRemoved(file, formik)}
            initFiles={init.photos}/>
        </FormSection>

        {auctionId &&
          <FormSection name={t('pages.admin.items.add.auctionInfo')}>
            <TextField
              fullWidth
              required
              id='customAuctionLotId'
              name='customAuctionLotId'
              label={t('pages.admin.items.add.customAuctionLotId')}
              type='number'
              {...getFormikProps(formik, 'customAuctionLotId')}/>

            <TextField
              fullWidth
              required
              id='startingBid'
              name='startingBid'
              label={t('pages.admin.items.add.startingBid')}
              type='number'
              {...getFormikProps(formik, 'startingBid')}/>

            <TextField
              fullWidth
              id='buyersPremium'
              name='buyersPremium'
              InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
              label={t('pages.admin.items.add.buyersPremium')}
              type='number'
              {...getFormikProps(formik, 'buyersPremium')}/>

            <TextField
              fullWidth
              id='sellersCommission'
              name='sellersCommission'
              InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
              label={t('pages.admin.items.add.sellersCommission')}
              type='number'
              {...getFormikProps(formik, 'sellersCommission')}/>
          </FormSection>
        }
      </FormWrapper>
    </>
  )
}