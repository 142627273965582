import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import {
  ADMIN_AUCTIONS,
  ADMIN_AUCTIONS_ADD,
  ADMIN_ITEMS,
  ADMIN_ITEMS_ADD, ADMIN_SELLERS, ADMIN_SELLERS_ADD
} from "../navigation/CONSTANTS";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const menuItems = (t) => [
  {
    id: t('components.navigator.catalog'),
    children: [
      { id: t('components.navigator.catalogItemList'), icon: <PeopleIcon />, link: ADMIN_ITEMS},
      { id: t('components.navigator.catalogItemCreate'), icon: <DnsRoundedIcon />, link: ADMIN_ITEMS_ADD},
    ],
  },
  {
    id: t('components.navigator.auction'),
    children: [
      { id: t('components.navigator.auctionList'), icon: <PeopleIcon />, link: ADMIN_AUCTIONS, },
      { id: t('components.navigator.auctionCreate'), icon: <DnsRoundedIcon />, link: ADMIN_AUCTIONS_ADD, },
    ],
  },
  {
    id: t('components.navigator.client'),
    children: [
      { id: t('components.navigator.clientSellerList'), icon: <PeopleIcon />, link: ADMIN_SELLERS, },
      { id: t('components.navigator.clientSellerCreate'), icon: <DnsRoundedIcon />, link: ADMIN_SELLERS_ADD, },
    ],
  },
];

const homeItem = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { ...other } = props;
  let {pathname} = useLocation();
  let navigate = useNavigate();
  const {t} = useTranslation();

  const navigateTo = (link) => {
    navigate(link);
    if(props.onClose) {
      props.onClose();
    }
  }

  return (
    <Drawer variant="permanent" {...other} >
      <List disablePadding sx={{ bgcolor: '#101F33' }}>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Coin Auction
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={homeItem}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>{t('components.navigator.websiteLink')}</ListItemText>
          </ListItemButton>
        </ListItem>

        {menuItems(t).map(({ id, children }) => (
          <Box key={id}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, link }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton selected={pathname === link} sx={item} onClick={(e) => navigateTo(link)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}

            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
