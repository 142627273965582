import {PageHeader} from "../../../components/PageHeader";
import * as React from "react";
import {PageContent} from "../../../components/PageContent";
import {AuctionsAutocomplete} from "../Buyers/AuctionsAutocomplete";
import {useFormik} from "formik";
import {FormWrapper} from "../../../components/FormWrapper";
import {FormSection} from "../../../components/FormSection";
import {DataTable} from "../../../components/DataTable";
import {useEffect, useState} from "react";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import Box from "@mui/material/Box";
import {StaticImage} from "../../../components/StaticImage";
import {useTranslation} from "react-i18next";
import {convertArrayToObject, withDefinedFields} from "../../../utils/idUtils";
import {BatchAssignSearchFilter} from "./BatchAssignSearchFilter";
import {useNavigate, useParams} from "react-router-dom";
import {
  ADMIN_AUCTIONS_SELLERS,
  ADMIN_AUCTIONS_SELLERS_DETAIL,
  AUCTION_ID,
  SELLER_ID
} from "../../../navigation/CONSTANTS";
import useUser from "../../../hooks/useUser";
import {Typography} from "@mui/material";

const columns = function (t) {
  return [
    {
      field: 'customAuctionLotId',
      headerName: t('general.tableCol.customAuctionLotId'),
      disableColumnMenu: true,
      editable: true,
      sortable: false,
      width: 100,
      align: 'right'
    },
    {
      field: 'name',
      headerName: t('general.tableCol.name'),
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="10px">
            <StaticImage
              variant="FRONT"
              photo={params.row.photo}
              sx={{
                height: '40px',
                width: '40px',
              }}
            />
            <Box sx={{lineHeight: '40px'}}>
              <p>{params.row.lot.name}</p>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'ownerReference',
      headerName: t('general.tableCol.seller'),
      disableColumnMenu: true,
      sortable: false,
      width: 100
    },
  ];
};

export const BatchAssign = () => {
  const {sellerId} = useParams();
  const navigate = useNavigate();
  const [auctionItems, setAuctionItems] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [searchCriteria, setSearchCriteria] = useState({});
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [seller, isLoadingSeller] = useUser(sellerId);
  const {t} = useTranslation();
  const axios = useAxiosAuth();
  const formik = useFormik(
    {
      initialValues: {
      },
      onSubmit: async (values) => {
        try {
          let auctionId = values.auction;
          let response = await axios.patch(`/api/auctions/${auctionId}/sellers/${sellerId}/lots`, {
            customAuctionLotIds: Object.keys(selectedRowData).map(customIdString => +customIdString)
          });

          alert("Updated " + response.data?.rowsUpdated + " rows");
          navigate(ADMIN_AUCTIONS_SELLERS_DETAIL.replace(AUCTION_ID, auctionId).replace(SELLER_ID, sellerId));
        } catch (e) {
          console.error(e)
        }
      }
    }
  )

  useEffect(() => {
    setIsLoadingItems(true);
  }, [paginationModel]);

  useEffect(() => {
    if(formik.values.auction) {
      setIsLoadingItems(true);
    }
  }, [formik.values.auction]);

  useEffect(() => {
    let isMounted = true;
    if(!formik.values.auction) {
      setIsLoadingItems(false);
      return;
    }

    if(!isLoadingItems) {
      return;
    }

    const fetchData = async () => {
      try {
        let response = await axios.get(`/api/auctions/${formik.values.auction}/lots`, {
          params: {
            ...searchCriteria,
            page: paginationModel.page,
            size: paginationModel.pageSize
          }
        });
        isMounted && setAuctionItems(response.data?.data);
        isMounted && setRowCount(response.data?.page?.totalCount || 0);
      } catch (e) {
        console.error(e);
      } finally {
        isMounted && setIsLoadingItems(false);
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    }
  }, [isLoadingItems])

  let gridOptions = {
    paginationMode: 'server',
    pageSizeOptions: [10],
    initialState: {
      pagination: {paginationModel: {pageSize: 10, page: 0}},
    },
    onPaginationModelChange: setPaginationModel,
    loading: isLoadingItems,
    rowCount,
    checkboxSelection: true,
    keepNonExistentRowsSelected: true,
    onRowSelectionModelChange: (newRowSelectionModel) => {
      setRowSelectionModel(newRowSelectionModel);
      const selectedIDs = new Set(newRowSelectionModel);
      const unselectedIDs = rowSelectionModel
        .filter((rowId) => !selectedIDs.has(rowId));

      const selectedNewData = auctionItems
        .filter((row) => selectedIDs.has(row.id));

      const mergedData = {...convertArrayToObject(selectedNewData, 'customAuctionLotId'), ...selectedRowData};
      unselectedIDs.forEach(unselectedId => {
        delete mergedData[unselectedId];
      });

      setSelectedRowData(mergedData);
    },
  }

  const onSearchCriteriaChange = (change) => {
    setSearchCriteria(withDefinedFields({...searchCriteria, ...change}));
    setIsLoadingItems(true);
  }

  return (
    <>
      <PageHeader header={t("pages.admin.sellers.batchAssign.menuTitle")}>
      </PageHeader>

      <PageContent>
        <FormWrapper onSubmit={formik.handleSubmit} submitDisabled={!(rowSelectionModel && rowSelectionModel.length)}>
          <Box display="flex" gap={1}>
            <Box>
              <Typography variant="h4">{t("pages.admin.sellers.batchAssign.sellerRef")}</Typography>
            </Box>
            <Box>
              {!isLoadingSeller && seller.reference}
            </Box>
          </Box>

          <FormSection name={"1. " + t("pages.admin.sellers.batchAssign.pickAuction")}>
            <AuctionsAutocomplete
              fullWidth
              label={"Auction"}
              formik={formik}
            />
          </FormSection>

          <FormSection name={"2. " +t("pages.admin.sellers.batchAssign.pickLots")} visible={!!formik.values.auction}>
            <DataTable
              rows={auctionItems}
              columns={columns(t, rowSelectionModel, selectedRowData)}
              gridOptions={gridOptions}
              pagination={paginationModel}
              addNew={<Box/>}
              disableRowClick>

              <BatchAssignSearchFilter onSearchCriteriaChange={onSearchCriteriaChange}/>
            </DataTable>
          </FormSection>
        </FormWrapper>
      </PageContent>
    </>
  );
}