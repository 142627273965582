import {axiosAuth} from "../api/axios";
import {useEffect, useState} from "react";

const useCategories = (auctionId) => {

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  useEffect( () => {
    const getCategories = async () => {
      try {
        if (!auctionId) {
          return;
        }
        setIsLoading(true);
        const response = await axiosAuth.get(`/api/auction/${auctionId}/categories`);
        setCategories(mapCategoriesToFullPaths(response.data?.data || []));
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    getCategories();
  }, [auctionId])


  const mapCategoriesToFullPaths = (categories) => {
    const parentMap = {};

    categories.forEach(category => {
      if (parentMap[category.id]) {
        return;
      }
      parentMap[category.id] = category;
    });

    function getNameRecursively(category) {
      if (!category.parentId) {
        return category.name;
      }
      return `${getNameRecursively(parentMap[category.parentId])}/${category.name}`
    }

    return categories.map(category => ({
      id: category.id,
      fullName: getNameRecursively(category)
    }));
  }

  return [categories, isLoading];
}

export default useCategories;