import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select, TextField
} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import {getFormikProps} from "../../../hooks/getFormikProps";
import * as React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

export const AddCategoryDialog = ({auctionId, category, onClose, onAdd, categories}) => {
  const isEdit = !!category;
  const axios = useAxiosAuth();
  const {t} = useTranslation();


  const init = category || {};
  const formik = useFormik(
    {
      initialValues: {
        categoryName: init.name || '',
        parentId: init.parentId || 0,
      },
      validationSchema: Yup.object({
        categoryName: Yup.string()
          .required(t('general.validations.required')),
        parentId: Yup.string()
          .required(t('general.validations.required')),
      }),
      onSubmit: async (values) => {
        try {

          const payload = {
            name: values.categoryName,
            parentId: values.parentId || null,
          }

          const newState = isEdit
            ? await axios.put(`/api/auction/${auctionId}/categories/${category.id}`, payload)
            : await axios.post(`/api/auction/${auctionId}/categories`, payload);

          onAdd({isEdit, data: newState.data});
        } catch (err) {
          console.error(err);
        }
      }
    });

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>{t('pages.admin.auctions.categories.addNewCategory')}</DialogTitle>
        <DialogContent>
          <TextField
            required
            fullWidth
            sx={{my: 1, mb:2}}
            autoComplete='off'
            id='categoryName'
            name='categoryName'
            label={t('pages.admin.auctions.categories.category')}
            {...getFormikProps(formik, 'categoryName')}
          />

          <TextField
            fullWidth
            select
            sx={{mb: 1}}
            id="parentId"
            name="parentId"
            label={t('pages.admin.auctions.categories.parent')}
            {...getFormikProps(formik, 'parentId')}
          >
            <MenuItem value={0}>(root)</MenuItem>
            {categories
              .map((node) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.name}
                </MenuItem>
              ))}
          </TextField>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{t('general.buttons.cancel')}</Button>
          <Button
            disabled={!(formik.isValid && formik.dirty)}
            onClick={formik.submitForm}
          >
            {isEdit ? t('general.buttons.edit') : t('general.buttons.add')}
            {formik.isSubmitting && (<CircularProgress size={20}/>)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
