import {PageHeader} from "../../../components/PageHeader";
import * as React from "react";
import {useState} from "react";
import {FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {FormSection} from "../../../components/FormSection";
import {DateTimePicker} from "@mui/x-date-pickers";
import {AuctionTable} from "./AuctionTable";
import {FormWrapper} from "../../../components/FormWrapper";
import {PageContent} from "../../../components/PageContent";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {getDateTimeFormikProps, getFormikProps} from "../../../hooks/getFormikProps";
import {useNavigate} from "react-router-dom";
import {ADMIN_AUCTIONS_DETAIL, AUCTION_ID} from "../../../navigation/CONSTANTS";
import {useTranslation} from "react-i18next";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import dayjs from "dayjs";

export const AddAuction = ({auction}) => {
  const isEdit = !!auction;
  const navigate = useNavigate();
  const {t} = useTranslation();
  const axiosAuth = useAxiosAuth();

  const auctionTableInitData = [
    {
      from: '0',
      to: '10',
      priceStep: '1'
    }
  ];

  const init = auction || {};
  const formik = useFormik({
    initialValues: {
      auctionName: init.name || '',
      description: init.description || '',
      startDate: date(init.startDate) || null,
      endDate: date(init.endDate) || null,
      limitStartDate: date(init.limitStartDate) || null,
      limitEndDate: date(init.limitEndDate) || null,
      auctionTable: init.auctionTable?.data || auctionTableInitData,
      buyersPremium: init.buyersPremium || 2,
      sellersCommission: init.sellersCommission || 2,
    },
    validationSchema: Yup.object({
      auctionName: Yup.string()
        .max(50, t('general.validations.required', {max: 50}))
        .required(t('general.validations.required')),
      startDate: Yup.date()
        .required(t('general.validations.required')),
      endDate: Yup.date()
        .required(t('general.validations.required')),
    }),
    onSubmit: async (values) => {
      let isMounted = true;
      try {
        setIsLoading(true);
        const data = JSON.stringify({
          name: values.auctionName,
          startDate: values.startDate.toISOString(),
          endDate: values.endDate.toISOString(),
          description: values.description,
          limitStartDate: values.limitStartDate?.toISOString(),
          limitEndDate: values.limitEndDate?.toISOString(),
          buyersPremium: values.buyersPremium,
          sellersCommission: values.sellersCommission,
          auctionTable: { data: values.auctionTable }
        });

        const response = isEdit
          ? await axiosAuth.put(`/api/auctions/${auction.id}`, data)
          : await axiosAuth.post('/api/auctions', data)


        navigate(ADMIN_AUCTIONS_DETAIL.replace(AUCTION_ID, response.data?.id))
        isMounted = false;
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false)
      }
    }
  });

  function date(isoString) {
    return isoString && dayjs(isoString);
  }

  let [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <PageHeader header={t('pages.admin.auctions.add.header')}>
      </PageHeader>

      <PageContent>
        <FormWrapper onSubmit={formik.handleSubmit} submitDisabled={!(formik.isValid && formik.dirty)}
                     loading={isLoading}>
          <FormSection name={t('pages.admin.auctions.add.basicInfo')}>
            <TextField
              required
              fullWidth
              autoComplete='off'
              id='auctionName'
              name='auctionName'
              label={t('pages.admin.auctions.add.name')}
              {...getFormikProps(formik, 'auctionName')}/>
            <DateTimePicker
              fullWidth
              disablePast={!isEdit}
              label={t('pages.admin.auctions.add.start')}
              name='startDate'
              value={formik.values.startDate}
              onChange={(value) => formik.setFieldValue("startDate", value, true)}
              format={"DD.MM.YYYY HH:mm"}
              slotProps={{
                textField: {
                  name: 'startDate',
                  required: true,
                  fullWidth: true,
                  ...getDateTimeFormikProps(formik, 'startDate')
                }
              }}
              ampm={false}
              />
            <DateTimePicker
              fullWidth
              disablePast={!isEdit}
              label={t('pages.admin.auctions.add.end')}
              name='endDate'
              value={formik.values.endDate}
              onChange={(value) => formik.setFieldValue("endDate", value, true)}
              format={"DD.MM.YYYY HH:mm"}
              slotProps={{
                textField: {
                  name: 'endDate',
                  required: true,
                  fullWidth: true,
                  ...getDateTimeFormikProps(formik, 'endDate')
                }
              }}
              ampm={false}/>
            <TextField
              fullWidth
              autoComplete='off'
              id='description'
              name='description'
              label={t('pages.admin.auctions.add.description')}
              multiline
              rows={4}
              {...getFormikProps(formik, 'description')}/>
          </FormSection>

          <FormSection name={t('pages.admin.auctions.add.auctionDetails')}>
            <DateTimePicker
              label={t('pages.admin.auctions.add.limitStart')}
              value={formik.values.limitStartDate}
              onChange={(value) => formik.setFieldValue("limitStartDate", value, true)}
              format={"DD.MM.YYYY HH:mm"}
              slotProps={{
                textField: {
                  fullWidth: true,
                  ...getDateTimeFormikProps(formik, 'limitStartDate')
                }
              }}
              ampm={false}/>
            <DateTimePicker
              label={t('pages.admin.auctions.add.limitEnd')}
              value={formik.values.limitEndDate}
              onChange={(value) => formik.setFieldValue("limitEndDate", value, true)}
              format={"DD.MM.YYYY HH:mm"}
              slotProps={{
                textField: {
                  fullWidth: true,
                  ...getDateTimeFormikProps(formik, 'limitEndDate')
                }
              }}
              ampm={false}/>
            <TextField
              fullWidth
              disabled
              id='currency'
              name='currency'
              label={t('pages.admin.auctions.add.currency')}
              value="EUR"
            />
            <TextField
              fullWidth
              InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
              InputLabelProps={{shrink: true}}
              label={t('pages.admin.auctions.add.buyersPremium')}
              name='buyersPremium'
              type='number'
              {...formik.getFieldProps('buyersPremium')}/>
            <TextField
              fullWidth
              InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
              InputLabelProps={{shrink: true}}
              label={t('pages.admin.auctions.add.sellersCommission')}
              name='margin'
              type='number'
              {...formik.getFieldProps('sellersCommission')}/>
          </FormSection>

          <FormSection name={t('pages.admin.auctions.add.auctionTable.title')}>
            <AuctionTable fieldName='auctionTable' formik={formik} />
          </FormSection>
        </FormWrapper>
      </PageContent>
    </>
  )
}